import { Box } from "@mui/material";
import { MODAL_TYPE, PATHS } from "Constants/index";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setModal } from "Redux/appSlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { PostIcon } from "../../icons";
import { OnboardingItem } from "../OnboardingItem/OnboardingItem";

export const OnboardingList = ({
  status,
  communityGuidelinesAccepted,
  postsCount,
}) => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();

  const openGuidelinesModal = () => {
    dispatch(
      setModal({
        open: true,
        type: MODAL_TYPE.COMMUNITY_GUIDELINES,
        payload: {},
      })
    );
  };

  return (
    <Box>
      <OnboardingItem
        title={t("community.oboarding2Title")}
        description={t("community.oboarding2Description")}
        buttonText={t("community.oboarding2ActionText")}
        isShowing={!communityGuidelinesAccepted}
        action={openGuidelinesModal}
      />
      <OnboardingItem
        title={t("community.oboarding3Title")}
        description={t("community.oboarding3Description")}
        buttonText={t("community.oboarding3ActionText")}
        isShowing={!postsCount}
        // isDisabled={
        //   !communityGuidelinesAccepted || status !== USER_ME_STATUS.VERIFIED
        // }
        isDisabled={!communityGuidelinesAccepted}
        btnIcon={<PostIcon />}
        action={() => navigate(PATHS.NEW_POST)}
      />
    </Box>
  );
};
