import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as PlusIcon } from "assets/svg/plus-icon.svg";
import { CustomSelect } from "Components/CustomSelect/CustomSelect";
import { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import theme from "theme/theme";

import { getTimeTimestampFomString } from "../../../../utils";
import styles from "./ScheduledItem.module.scss";
import { getTimesSlot } from "./utils";

export const ScheduledItem = ({
  duration,
  dayAbbr,
  isCheckbox = true,
  labelWrapperClassName,
  wrapperClassName,
  addButtonClassName,
  setSlots,
  slots,
}) => {
  const { t } = useContext(LocalizationContext);
  const isXS = useMediaQuery(theme.breakpoints.down(480));

  const addEmptySlot = () => setSlots([...slots, { start: "", end: "" }]);

  const deleteSlot = (index) => () => {
    const newArr = slots.filter((item, i) => i !== index);
    setSlots(newArr);
  };

  const updateSlotByIndex = (index, name) => (e) => {
    const newSlots = [...slots];
    newSlots[index][name] = e.target.value;

    if (name === "start") {
      if (
        getTimeTimestampFomString(e.target.value) >=
        getTimeTimestampFomString(newSlots[index].end)
      ) {
        newSlots[index].end = "";
      }
    } else if (name === "end") {
      if (
        getTimeTimestampFomString(e.target.value) <=
        getTimeTimestampFomString(newSlots[index].start)
      ) {
        newSlots[index].start = "";
      }
    }
    setSlots(newSlots);
  };

  const checkboxHandler = (e) => {
    const checked = e.target.checked;
    if (checked) {
      addEmptySlot();
    } else {
      setSlots([]);
    }
  };

  const isDisableAddNewItem =
    slots.at(-1) &&
    (!slots.at(-1)?.start?.trim() || !slots.at(-1)?.end?.trim());

  const getTimestampSlots = (arr) => {
    return arr.map((item) => {
      if (item.start && item.end) {
        return {
          start: getTimeTimestampFomString(item.start),
          end: getTimeTimestampFomString(item.end),
        };
      }
      return item;
    });
  };

  const timestampSlots = getTimestampSlots(slots);

  const isDisableOption = ({ value, arr, currentIndex }) => {
    if (value) {
      const timestampValue = getTimeTimestampFomString(value);
      return arr.some((item, index) => {
        if (index !== currentIndex) {
          return item.start <= timestampValue && timestampValue <= item.end;
        }
        return false;
      });
    }
    return false;
  };

  return (
    <Box className={styles.scheduledItemBox}>
      <Box
        className={`${styles.wrapper} ${
          wrapperClassName ? wrapperClassName : ""
        }`}
      >
        <FormControl
          className={`${styles.labelWrapper} ${
            labelWrapperClassName ? labelWrapperClassName : ""
          }`}
        >
          {isCheckbox ? (
            <FormControlLabel
              className={styles.label}
              control={
                <Checkbox
                  name="tipIsHidden"
                  onChange={checkboxHandler}
                  checked={!!slots.length}
                />
              }
              label={<Typography variant="body1">{dayAbbr}</Typography>}
            />
          ) : (
            <Box className={styles.label}>
              <Typography variant="body1">{dayAbbr}</Typography>
            </Box>
          )}
          {!slots.length ? (
            <Typography className="opacity_07" variant="body2">
              {t("default.unavailable")}
            </Typography>
          ) : null}
        </FormControl>
        {!isXS ? (
          <Box className={styles.optionsWrapper}>
            {slots.length
              ? slots.map((item, index) => (
                  <Box key={item.start} className={styles.options}>
                    <CustomSelect
                      options={getTimesSlot({
                        startTime: new Date().setHours(0, 0, 0),
                        endTime: new Date().setHours(23, 60 - duration, 0),
                        duration,
                      }).map((item) => ({
                        label: item,
                        value: item,
                        disabled: isDisableOption({
                          value: item,
                          arr: timestampSlots,
                          currentIndex: index,
                        }),
                      }))}
                      activeValue={slots[index]?.start}
                      selectClassName={styles.select}
                      dropdownMenuClassName={styles.dropdownMenu}
                      onChange={updateSlotByIndex(index, "start")}
                    />
                    -
                    <CustomSelect
                      options={getTimesSlot({
                        startTime: new Date().setHours(0, 0, 0),
                        endTime: new Date().setHours(24, 0, 0),
                        duration,
                      }).map((item) => ({
                        label: item,
                        value: item,
                        disabled: isDisableOption({
                          value: item,
                          arr: timestampSlots,
                          currentIndex: index,
                        }),
                      }))}
                      selectClassName={styles.select}
                      dropdownMenuClassName={styles.dropdownMenu}
                      activeValue={slots[index]?.end}
                      onChange={updateSlotByIndex(index, "end")}
                    />
                    <IconButton
                      className={`${styles.deleteButton} ${styles.actionButton}`}
                      onClick={deleteSlot(index)}
                    >
                      <PlusIcon />
                    </IconButton>
                  </Box>
                ))
              : null}
          </Box>
        ) : null}
        <IconButton
          className={`${styles.addButton} ${styles.actionButton} ${
            addButtonClassName ? addButtonClassName : ""
          }`}
          onClick={addEmptySlot}
          disabled={isDisableAddNewItem}
        >
          <PlusIcon />
        </IconButton>
      </Box>
      {isXS ? (
        <Box className={styles.optionsWrapper}>
          {slots.length
            ? slots.map((item, index) => (
                <Box key={item.start} className={styles.options}>
                  <CustomSelect
                    options={getTimesSlot({
                      startTime: new Date().setHours(0, 0, 0),
                      endTime: new Date().setHours(23, 30, 0),
                      duration,
                    }).map((item) => ({
                      label: item,
                      value: item,
                      disabled: isDisableOption({
                        value: item,
                        arr: timestampSlots,
                        currentIndex: index,
                      }),
                    }))}
                    activeValue={slots[index]?.start}
                    selectClassName={styles.select}
                    dropdownMenuClassName={styles.dropdownMenu}
                    onChange={updateSlotByIndex(index, "start")}
                  />
                  -
                  <CustomSelect
                    options={getTimesSlot({
                      startTime: new Date().setHours(0, 0, 0),
                      endTime: new Date().setHours(23, 30, 0),
                      duration,
                    }).map((item) => ({
                      label: item,
                      value: item,
                      disabled: isDisableOption({
                        value: item,
                        arr: timestampSlots,
                        currentIndex: index,
                      }),
                    }))}
                    selectClassName={styles.select}
                    dropdownMenuClassName={styles.dropdownMenu}
                    activeValue={slots[index]?.end}
                    onChange={updateSlotByIndex(index, "end")}
                  />
                  <IconButton
                    className={`${styles.deleteButton} ${styles.actionButton}`}
                    onClick={deleteSlot(index)}
                  >
                    <PlusIcon />
                  </IconButton>
                </Box>
              ))
            : null}
        </Box>
      ) : null}
    </Box>
  );
};
