import React from "react";

import formats from "./ToolbarOptions.js";

const Options = React.memo(({ className, options }) => (
  <select className={className}>
    <option selected="selected" />
    {options.map((value, i) => (
      <option value={value} key={i} />
    ))}
  </select>
));

const SingleOption = React.memo(({ className, value }) => {
  return <button className={className} value={value} />;
});

const CustomToolbar = ({ editorToolbarId, customFormats = formats }) => (
  <div id={editorToolbarId} className="toolbar">
    {customFormats.map((classes, index) => {
      return (
        <span className="ql-formats" key={index}>
          {classes.map((formatData, i) =>
            formatData.options ? (
              <Options
                key={i}
                className={formatData.className}
                options={formatData.options}
              />
            ) : (
              <SingleOption
                key={i}
                value={formatData.value}
                className={formatData.className}
              />
            )
          )}
        </span>
      );
    })}
  </div>
);
export default CustomToolbar;
