import { Box, Button, Skeleton, Typography } from "@mui/material";
import { REWARDS_ACTION_TYPE, TUTORIALS_PROPS } from "Constants/index";
import { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { FileUploader } from "./components/FileUploader/FileUploader";
import { RewardChat } from "./components/RewardChat/RewardChat";
import { CrossIcon } from "./icons";
import styles from "./RewardsSection.module.scss";

export const RewardsSection = ({
  files,
  setFiles,
  rewardType,
  isLoading,
  setRewardType,
  setMessage,
  message,
  rewardMessage,
  setRewardMessage,
  isDisabled,
}) => {
  const { t } = useContext(LocalizationContext);

  const clearState = () => {
    setRewardType(null);
  };

  const setTypeHandler = (value) => () => {
    if (value === rewardType) {
      clearState();
    } else {
      setRewardType(value);
    }
  };

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        className={`${styles.skeleton} ${styles.content}`}
      />
    );
  }

  return (
    <Box className={styles.content}>
      <Typography variant="h5_display_medium" className={styles.contentTitle}>
        {t("limitedEdition.cardReward")}
        <Typography variant="h5" className="opacity_07">
          {`(${t("default.optional")})`}
        </Typography>
      </Typography>
      <Box className={styles.actions}>
        {rewardType === REWARDS_ACTION_TYPE.HIDDEN_CONTENT || !rewardType ? (
          <Button
            variant="outlined"
            size="small"
            disabled={isDisabled}
            onClick={setTypeHandler(REWARDS_ACTION_TYPE.HIDDEN_CONTENT)}
          >
            {t("limitedEdition.hiddenContent")}
            {rewardType === REWARDS_ACTION_TYPE.HIDDEN_CONTENT ? (
              <CrossIcon />
            ) : null}
          </Button>
        ) : null}
        {rewardType === REWARDS_ACTION_TYPE.PREMIUM_CHAT || !rewardType ? (
          <Button
            variant="outlined"
            size="small"
            disabled={isDisabled}
            onClick={setTypeHandler(REWARDS_ACTION_TYPE.PREMIUM_CHAT)}
          >
            {t("limitedEdition.premiumChat")}
            {rewardType === REWARDS_ACTION_TYPE.PREMIUM_CHAT ? (
              <CrossIcon />
            ) : null}
          </Button>
        ) : null}
        {rewardType === REWARDS_ACTION_TYPE.FOLLOW_BACK || !rewardType ? (
          <Button
            variant="outlined"
            size="small"
            disabled={isDisabled}
            onClick={setTypeHandler(REWARDS_ACTION_TYPE.FOLLOW_BACK)}
          >
            {t("limitedEdition.followBack")}
            {rewardType === REWARDS_ACTION_TYPE.FOLLOW_BACK ? (
              <CrossIcon />
            ) : null}
          </Button>
        ) : null}
        {rewardType === REWARDS_ACTION_TYPE.VIDEO_CALL || !rewardType ? (
          <Button
            variant="outlined"
            size="small"
            disabled={isDisabled}
            onClick={setTypeHandler(REWARDS_ACTION_TYPE.VIDEO_CALL)}
          >
            {t("limitedEdition.videoCall")}
            {rewardType === REWARDS_ACTION_TYPE.VIDEO_CALL ? (
              <CrossIcon />
            ) : null}
          </Button>
        ) : null}
        {rewardType === REWARDS_ACTION_TYPE.LIVESTREAM || !rewardType ? (
          <Button
            variant="outlined"
            size="small"
            onClick={setTypeHandler(REWARDS_ACTION_TYPE.LIVESTREAM)}
            disabled={true}
          >
            {t("limitedEdition.livestream")}
            {rewardType === REWARDS_ACTION_TYPE.LIVESTREAM ? (
              <CrossIcon />
            ) : null}
          </Button>
        ) : null}
        {rewardType === REWARDS_ACTION_TYPE.CUSTOM || !rewardType ? (
          <Button
            variant="outlined"
            size="small"
            onClick={setTypeHandler(REWARDS_ACTION_TYPE.CUSTOM)}
            disabled={true}
          >
            {t("limitedEdition.custom")}
            {rewardType === REWARDS_ACTION_TYPE.CUSTOM ? <CrossIcon /> : null}
          </Button>
        ) : null}
      </Box>
      <Box className={styles.footer}>
        <FileUploader
          files={files}
          setFiles={setFiles}
          isActive={rewardType === REWARDS_ACTION_TYPE.HIDDEN_CONTENT}
          emptyTitle={t("limitedEdition.fileUploaderTitle")}
          emptySubTitle={t("limitedEdition.fileUploaderSubTitle")}
          disabled={isDisabled}
        />
        <RewardChat
          isActive={rewardType === REWARDS_ACTION_TYPE.PREMIUM_CHAT}
          setMessage={setMessage}
          message={message}
          customAlertTitle={t("limitedEdition.premiumChatInfo")}
          tutorialName={TUTORIALS_PROPS.CREATE_CARD_PREMIUM_CHAT}
          disabled={isDisabled}
        />
        <RewardChat
          isActive={rewardType === REWARDS_ACTION_TYPE.FOLLOW_BACK}
          setRewardMessage={setRewardMessage}
          message={rewardMessage[rewardType]}
          customAlertTitle={t("limitedEdition.followBackChatInfo")}
          rewardType={rewardType}
          tutorialName={TUTORIALS_PROPS.CREATE_CARD_FOLLOW_BACK}
          disabled={isDisabled}
        />
        <RewardChat
          isActive={rewardType === REWARDS_ACTION_TYPE.VIDEO_CALL}
          setRewardMessage={setRewardMessage}
          message={rewardMessage[rewardType]}
          customAlertTitle={t("limitedEdition.videoCallChatInfo")}
          rewardType={rewardType}
          tutorialName={TUTORIALS_PROPS.CREATE_CARD_VIDEO_CALL}
          disabled={isDisabled}
        />
      </Box>
    </Box>
  );
};
