import { Box, LinearProgress } from "@mui/material";
import {
  PATHS,
  STRIPE_RETURN_URL,
  SUBSCRIPTIONS_PRICE,
  USER_BLOCKED,
  USER_ME_STATUS,
} from "Constants";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { setLoader } from "Redux/appSlice";
import { getStripeAccount } from "Redux/communitySlice";
import {
  getUsersMeCommunityGuidelinesAccepted,
  getUsersMeId,
} from "Redux/selectors/userSelector";
import { getEmulateId, isAuthenticated } from "Services/Auth/auth.service";

import { getAuthProcess } from "../Redux/selectors/appSelector";

export const StripeOnboardingReturnUrl = () => {
  const path = localStorage.getItem(STRIPE_RETURN_URL) || PATHS.EARNINGS;
  return (
    <Navigate
      to={path}
      state={{ subscriptionType: SUBSCRIPTIONS_PRICE.PAID }}
      replace
    />
  );
};
/**
 * Redirect back to stripe account url in cases when:
 * The link is expired (a few minutes went by since the link was created).
 * The user already visited the URL (the user refreshed the page or clicked back or forward in the browser).\
 * Your platform is no longer able to access the account.
 * The account has been rejected.
 */
export const StripeOnboardingRefreshUrl = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLoader(true));
    dispatch(getStripeAccount())
      .unwrap()
      .then((res) => {
        if (res?.url) window.location.href = res.url;
      })
      .catch((e) => {
        console.error(e);
        const path =
          localStorage.getItem(STRIPE_RETURN_URL) || PATHS.EDIT_COMMUNITY;
        return <Navigate to={path} replace />;
      })
      .finally(() => dispatch(setLoader(false)));
  }, []);
};
const NavigateTo = ({ to, ...rest }) => {
  const location = useLocation();
  if (location.pathname === to) return <Outlet />;
  return <Navigate to={to} replace {...rest} />;
};
export const RedirectTo = () => {
  const location = useLocation();
  const isUserLoaded = useSelector(getUsersMeId);
  const isAccepted = useSelector(getUsersMeCommunityGuidelinesAccepted);
  const isAuthProcess = useSelector(getAuthProcess);
  const isNotAuthenticated = !isAuthenticated() && !isAuthProcess;
  const userStatus = useSelector((state) => state.users.me.entities.status);
  const onfidoError = useSelector((state) => state.mainAppUser?.error);
  const userLevel = useSelector((state) => state.users.me.entities.level);

  const isGuidelinesNotAccepted = isUserLoaded && !isAccepted;
  const emailVerified = useSelector(
    (state) => state.users.me.entities.emailVerified
  );
  if (!getEmulateId()) {
    if (isNotAuthenticated) {
      const to = location.state?.redirectTo || PATHS.SIGNIN;
      localStorage.setItem("from", location.pathname);
      return <NavigateTo to={to} state={{ from: location }} />;
    }
    if (!isUserLoaded) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            flexFlow: "column",
            padding: "20px",
            borderRadius: "10px",
            minWidth: "150px",
          }}
        >
          <LinearProgress color="accent" sx={{ width: "100%" }} />
        </Box>
      );
    }
    if (onfidoError?.type === USER_BLOCKED)
      return <NavigateTo to={PATHS.ACCOUNT_SUSPENDED} />;

    if (!emailVerified) {
      return <NavigateTo to={PATHS.EMAIL_VERIFICATION} />;
    }
    if (userStatus === USER_ME_STATUS.NEW || !userLevel) {
      return <NavigateTo to={PATHS.PROFILE} />;
    }
    if (isGuidelinesNotAccepted) {
      if (location.pathname === PATHS.PROFILE) return <Outlet />;
      return <NavigateTo to={PATHS.COMMUNITY} />;
    }
  }
  return <Outlet />;
};

export const PublicRoute = () => {
  let location = useLocation();

  if (isAuthenticated()) {
    let path = getEmulateId() ? PATHS.PROFILE : PATHS.HOME;
    // pathname - from webapp (direct redirect)
    const urlParams = new URLSearchParams(window.location.search);
    const pathname = urlParams.get("pathname");
    if (pathname) {
      path = pathname;
    }
    return <Navigate to={path} state={{ from: location }} replace />;
  }
  return <Outlet />;
};

export const RequireAuth = () => {
  let location = useLocation();
  const isAuthProcess = useSelector(getAuthProcess);

  if (!isAuthenticated() && !isAuthProcess) {
    const to = location.state?.redirectTo || PATHS.SIGNIN;
    localStorage.setItem("from", location.pathname);
    return <Navigate to={to} state={{ from: location }} replace />;
  }

  return <Outlet />;
};
