export const FileIcon = () => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15.49" cy="15" r="14.5" fill="white" fillOpacity="0.2" />
    <circle cx="15.49" cy="15" r="14.5" fill="#191C38" fillOpacity="0.5" />
    <circle cx="15.49" cy="15" r="14.5" stroke="white" />
    <path
      d="M11.0017 11.5781V17.845C11.0017 19.0776 11.4681 20.2598 12.2982 21.1315C13.1283 22.0031 14.2542 22.4928 15.4282 22.4928C16.6022 22.4928 17.7281 22.0031 18.5582 21.1315C19.3884 20.2598 19.8547 19.0776 19.8547 17.845V10.0986C19.8547 9.27677 19.5438 8.48864 18.9904 7.90755C18.437 7.32645 17.6864 7 16.9037 7C16.1211 7 15.3705 7.32645 14.817 7.90755C14.2636 8.48864 13.9527 9.27677 13.9527 10.0986V17.2113C13.9527 17.4148 13.9909 17.6162 14.065 17.8042C14.1392 17.9922 14.2479 18.1629 14.3849 18.3068C14.5219 18.4507 14.6846 18.5648 14.8636 18.6427C15.0426 18.7205 15.2345 18.7606 15.4282 18.7606C15.8196 18.7606 16.1949 18.5974 16.4716 18.3068C16.7483 18.0163 16.9037 17.6222 16.9037 17.2113V11.6478"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CrossIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L6 6M11 11L6 6M6 6L11 1M6 6L1 11"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AudioIcon = () => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15.8301" cy="15" r="14.5" fill="white" fillOpacity="0.2" />
    <circle cx="15.8301" cy="15" r="14.5" fill="#191C38" fillOpacity="0.5" />
    <circle cx="15.8301" cy="15" r="14.5" stroke="white" />
    <path
      d="M12.9771 19.3812V11.2428C12.977 10.9262 13.0903 10.6201 13.2964 10.3799C13.5025 10.1396 13.7878 9.9811 14.1007 9.93299L20.0634 9.0154C20.2522 8.98636 20.4451 8.99849 20.6288 9.05098C20.8125 9.10346 20.9827 9.19505 21.1276 9.31946C21.2726 9.44387 21.389 9.59816 21.4688 9.77176C21.5486 9.94536 21.5898 10.1342 21.5898 10.3252V18.0562"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9771 13.4185L21.5898 12.0935"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9771 19.3812C12.9771 19.9083 12.7677 20.4139 12.3949 20.7866C12.0222 21.1594 11.5167 21.3688 10.9895 21.3688C10.4624 21.3688 9.95684 21.1594 9.5841 20.7866C9.21136 20.4139 9.00195 19.9083 9.00195 19.3812C9.00195 18.2834 9.89172 18.0562 10.9895 18.0562C12.0873 18.0562 12.9771 18.2834 12.9771 19.3812Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.5899 18.0562C21.5899 18.5834 21.3805 19.0889 21.0077 19.4617C20.635 19.8344 20.1294 20.0438 19.6023 20.0438C19.0752 20.0438 18.5696 19.8344 18.1969 19.4617C17.8241 19.0889 17.6147 18.5834 17.6147 18.0562C17.6147 16.9584 18.5045 16.7312 19.6023 16.7312C20.7001 16.7312 21.5899 16.9584 21.5899 18.0562Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
