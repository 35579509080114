import { Box } from "@mui/material";
import { AUTH, MainAppDomain, PATHS } from "Constants/index";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setAuthProcess, setLoader as setGlobalLoader } from "Redux/appSlice";
import { getAuthProcess } from "Redux/selectors/appSelector";
import { isAuthenticated } from "Services/Auth/auth.service";

import stylesAuth from "../Login/Auth.module.scss";

export const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const isAuthProcess = useSelector(getAuthProcess);

  useEffect(() => {
    if (!isAuthProcess) {
      if (!isAuthenticated()) {
        if (params.code) {
          localStorage.setItem(AUTH.REFERRAL_CODE, params.code);
          window.location.href = `${MainAppDomain}/sign-up/${params.code}`;
        } else {
          window.location.href = `${MainAppDomain}/sign-up`;
        }
      } else {
        dispatch(setGlobalLoader(false));
        dispatch(setAuthProcess(false));
        navigate(PATHS.HOME, { replace: true });
      }
    }
  }, [isAuthProcess]);

  return <Box className={stylesAuth.authContainer} />;
};
