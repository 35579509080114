import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as IconRight } from "assets/svg/icon-right.svg";
import React, { useContext, useEffect } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./Help.module.scss";

const Help = () => {
  const { t } = useContext(LocalizationContext);

  const handleChatClick = () => {
    window.zE("messenger:set", "zIndex", 99999);
    window.zE("messenger", "open");
  };

  useEffect(() => {
    return () => {
      if (window.zE) {
        window.zE("messenger", "close");
        window.zE("messenger:set", "zIndex", -99999);
      }
    };
  }, []);

  return (
    <Box className={styles.helpContainer}>
      <Box className={styles.helpContent}>
        <Typography variant="h3" className={styles.helpContentTitle}>
          {t("settings.help")}
        </Typography>
        <Box className={styles.tabs}>
          <IconButton className={styles.tabOption} onClick={handleChatClick}>
            <Typography variant="h6">
              {t("settings.chatWithSupport")}
            </Typography>
            <IconRight className={styles.icon} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Help;
