import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { EMULATE } from "Constants";
import {
  clearTokens,
  getEmulateId,
  getIdToken,
  setAuth,
} from "Services/Auth/auth.service";
import UserPool from "Services/userPool";
import { getAdminUserPool } from "Services/userPoolAdmin";

const API_URL = process.env.REACT_APP_API_HOST;
const MAIN_API_URL = process.env.REACT_APP_MAIN_API_HOST;

/**
 * Adds authorization headers to API calls
 */
const authInterceptor = async (request) => {
  const idToken = getIdToken();
  request.headers["Authorization"] = idToken ? `Bearer ${idToken}` : null;
  return request;
};

const emulateInterceptor = (request) => {
  const id = getEmulateId();
  if (id) {
    request.headers[EMULATE.HEADER] = id;
  }
  return request;
};

/**
 * Refresh expired tokens
 *
 * @param failedRequest
 * @returns
 */
function refreshExpiredTokens(failedRequest) {
  return new Promise((resolve, reject) => {
    let cognitoUser = UserPool.getCurrentUser();
    if (getEmulateId()) {
      const UserPoolAdmin = getAdminUserPool();
      cognitoUser = UserPoolAdmin.getCurrentUser();
    }
    if (!cognitoUser) {
      clearTokens();
      reject(new Error("No authenticated user found"));
      return;
    }
    cognitoUser?.getSession((err, res) => {
      if (!res) {
        return clearTokens() && reject(err);
      }
      const refresh_token = res.getRefreshToken();
      cognitoUser?.refreshSession(refresh_token, (err, session) => {
        const { idToken } = session;
        if (err) {
          clearTokens() && reject(err);
        } else {
          setAuth({ data: session });
          failedRequest.response.config.headers["Authorization"] =
            "Bearer " + idToken.jwtToken;
          resolve(failedRequest);
        }
      });
    });
  });
}

/**
 * Setup an API instance
 */
export const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    "ii-api-version": process.env.REACT_APP_API_VERSION,
  },
});
export const mainApi = axios.create({
  baseURL: MAIN_API_URL,
  headers: {
    "Content-Type": "application/json",
    "ii-api-version": process.env.REACT_APP_MAIN_API_VERSION,
  },
});
export const signTerms = (params) => {
  return api
    .post(`${API_URL}/public/leads/sign-terms`, params)
    .then((res) => res.data);
};
export const checkReferralCode = (code) => {
  return mainApi
    .get(
      `${MAIN_API_URL}/public/users/search?searchBy=inviteCode&value=${code}`
    )
    .then((res) => res.data);
};

/** Add interceptor */
api.interceptors.request.use(authInterceptor);
mainApi.interceptors.request.use(authInterceptor);
api.interceptors.request.use(emulateInterceptor);
mainApi.interceptors.request.use(emulateInterceptor);
createAuthRefreshInterceptor(api, refreshExpiredTokens);
createAuthRefreshInterceptor(mainApi, refreshExpiredTokens);
