import { Box, Typography } from "@mui/material";
import { ReactComponent as ArrowDownIcon } from "assets/svg/arrow-down.svg";
import { ReactComponent as SettingsIcon } from "assets/svg/icon-settings.svg";
import classNames from "classnames/bind";
import ActiveLink from "Components/ActiveLink";
import { PATHS } from "Constants";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./Settings.module.scss";

const SettingsMenu = ({ isMobile, onClickSettingsMenu, itemsClassNames }) => {
  const { t } = useContext(LocalizationContext);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const cx = classNames.bind(styles);

  const MenuItem = React.memo(
    ({
      to = null,
      onClick = isMobile ? onClickSettingsMenu : () => null,
      text,
      className,
    }) => {
      return (
        <ActiveLink
          to={to}
          className={cx("menuItem", "settingsMenuItem", className)}
          activeClass={`${styles.menuItemActive} ${styles.settingsMenuItem}`}
          onClick={onClick}
        >
          <span>{text}</span>
        </ActiveLink>
      );
    }
  );

  return (
    <Box className={`${styles.settingsMenu} ${isMenuOpen ? styles.open : ""}`}>
      <Box onClick={toggleMenu} className={styles.settingMenu}>
        <SettingsIcon className={`${styles.icon} ${styles.settingMenuIcon}`} />
        <Typography className={styles.menuItem}>
          {t("default.settings")}
        </Typography>
        <ArrowDownIcon
          className={`${styles.arrowDownIcon} ${styles.settingMenuArrowIcon}`}
        />
      </Box>
      <Box className={styles.dropdown}>
        <MenuItem
          to={PATHS.EDIT_COMMUNITY}
          text={t("default.myClub")}
          className={itemsClassNames?.[PATHS.EDIT_COMMUNITY]}
        />
        <MenuItem
          to={PATHS.SETTINGS_EMAIL_NOTIFICATIONS}
          text={t("settings.emailNotifications")}
        />
        <MenuItem
          text={t("settings.help")}
          to={`${PATHS.SETTINGS}/${PATHS.HELP}`}
        />
      </Box>
    </Box>
  );
};

export default SettingsMenu;
