import { Box, FormControlLabel, Typography } from "@mui/material";
import { SwitchIOS } from "Components/SwitchIOS/SwitchIOS";
import Title from "Components/Title";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "Redux/appSlice";
import {
  fetchEmailNotificationSettings,
  updateEmailNotificationSettings,
} from "Redux/usersSlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./Settings.module.scss";

const EmailNotificationsSettings = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const { entities: defaultSettings, loading } = useSelector(
    (state) => state.users.emailNotifications
  );
  const [settings, setSettings] = useState(null);

  const onSettingsItemChanged = (val, name) => {
    let params = {
      ...settings,
      [name]: val,
    };
    setSettings(params);
    dispatch(updateEmailNotificationSettings(params));
  };

  useEffect(() => {
    dispatch(fetchEmailNotificationSettings());
  }, []);

  useEffect(() => {
    setSettings(defaultSettings);
  }, [defaultSettings]);

  useEffect(() => {
    if (loading) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
  }, [loading]);

  return !loading ? (
    <Box className={styles.tabContainer}>
      <Title title={t("settings.emailNotifications")} />
      <Section title={t("settings.emailNotificationsTitleClub")}>
        <SettingItem
          label={t("settings.emailNotificationsNewSub")}
          description={t("settings.emailNotificationsDescNewSub")}
          name="creatorOnNewSubscriber"
          value={settings?.creatorOnNewSubscriber}
          onChange={(val) =>
            onSettingsItemChanged(val, "creatorOnNewSubscriber")
          }
        />
        <SettingItem
          label={t("settings.emailNotificationsLabelComment")}
          name="creatorOnNewComment"
          value={settings?.creatorOnNewComment}
          onChange={(val) => onSettingsItemChanged(val, "creatorOnNewComment")}
        />
        <SettingItem
          label={t("settings.emailNotificationsLabelDonate")}
          name="donate"
          value={settings?.donate}
          onChange={(val) => onSettingsItemChanged(val, "donate")}
        />
      </Section>

      <Section title={t("settings.emailNotificationsTitleMessages")}>
        <SettingItem
          label={t("settings.emailNotificationsLabelInbox")}
          description={t("settings.emailNotificationsDescInbox")}
          name="inboxMessages"
          value={settings?.inboxMessages}
          onChange={(val) => onSettingsItemChanged(val, "inboxMessages")}
        />
        <SettingItem
          label={t("settings.emailNotificationsLabelMessage")}
          description={t("settings.emailNotificationsDescMessage")}
          name="messageRequests"
          value={settings?.messageRequests}
          onChange={(val) => onSettingsItemChanged(val, "messageRequests")}
        />
      </Section>

      <Section title={t("settings.emailNotificationsTitleOthers")}>
        <SettingItem
          label={t("settings.emailNotificationsLabelCardSale")}
          description={t("settings.emailNotificationsDescCardSale")}
          name="cardSale"
          value={settings?.cardSale}
          onChange={(val) => onSettingsItemChanged(val, "cardSale")}
        />
      </Section>

      <Section title={t("settings.emailNotificationsNews")}>
        <SettingItem
          label={t("settings.emailNotificationsLabelProduct")}
          description={t("settings.emailNotificationsDescNews")}
          name="productUpdates"
          value={settings?.productUpdates}
          onChange={(val) => onSettingsItemChanged(val, "productUpdates")}
        />
      </Section>
    </Box>
  ) : null;
};

const Section = ({ title, children }) => (
  <Box className={styles.emailNotificationSection}>
    <Typography variant="h6">{title}</Typography>
    {children}
  </Box>
);

const SettingItem = ({ label, description, name, value, onChange }) => {
  const [isChecked, setIsChecked] = React.useState(value);
  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  const disabled = value === undefined;

  return (
    <Box className={styles.emailNotificationItem}>
      <FormControlLabel
        labelPlacement="start"
        control={
          <SwitchIOS
            className={styles.customSwitch}
            checked={isChecked}
            onChange={handleChange}
            name={name}
            color="primary"
            disabled={disabled}
          />
        }
        label={
          <Box>
            <Typography className={styles.emailNotificationItemLabel}>
              {label}
            </Typography>
            {description ? (
              <Typography className={styles.emailNotificationItemDescription}>
                {description}
              </Typography>
            ) : null}
          </Box>
        }
      />
    </Box>
  );
};

export default EmailNotificationsSettings;
