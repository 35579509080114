import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./CropModal.module.scss";
import { ErrorInfo, IconClose, UploadIcon } from "./icons";
import { centerAspectCrop } from "./utils";

export const CropModal = ({
  imgFile,
  isOpen,
  setOpen,
  aspect = 16 / 9,
  keepSelection,
  locked,
  submitHandler,
  minImgHeight,
  minImgWidth,
  resetImageFile,
}) => {
  const imgRef = useRef();
  const cropRefWrapper = useRef();
  const { t } = useContext(LocalizationContext);

  const [imgSrc, setImgSrc] = useState(null);
  const [fileData, setFileData] = useState(imgFile);

  const [crop, setCrop] = useState({
    unit: "%",
    width: 90,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [isErrorImgSize, setErrorImgSize] = useState(false);

  const isError = () => {
    if (minImgHeight && minImgWidth) {
      if (
        imgRef.current?.naturalHeight < minImgHeight &&
        imgRef.current?.naturalWidth < minImgWidth
      ) {
        return true;
      }
    }
    return false;
  };

  const onImageLoad = (e) => {
    if (cropRefWrapper.current) {
      cropRefWrapper.current.style.opacity = 1;
      setErrorImgSize(isError());
    }
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(
        centerAspectCrop({
          mediaWidth: width,
          mediaHeight: height,
          aspect,
          width: 100,
        })
      );
    }
  };

  const getCroppedImg = (sourceImage, crop) => {
    const canvas = document.createElement("canvas");

    // image sizes of returned image
    const width = minImgWidth || 1200;
    const height = minImgHeight || 1200;

    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext("2d");

    const image = new Image();
    image.src = sourceImage;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      width,
      height
    );

    try {
      return new Promise((resolve) => {
        canvas.toBlob((file) => {
          resolve(file);
        }, fileData.type || "image/jpeg");
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const makeClientCrop = async (crop) => {
    if (imgSrc && crop.width && crop.height) {
      const croppedImg = await getCroppedImg(imgSrc, crop, "croppedImage.jpeg");
      setCompletedCrop(croppedImg);
    }
  };

  const onClose = () => {
    setOpen(false);
    resetImageFile(null);
  };
  const submit = () => {
    submitHandler(completedCrop);
    onClose();
  };

  const reset = () => {
    setCompletedCrop(null);
    setCrop(null);
    setImgSrc(null);
  };

  useEffect(() => {
    if (fileData) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(fileData);
    }
  }, [fileData]);

  useEffect(() => {
    reset();
  }, []);

  if (!imgFile) {
    return null;
  }

  return (
    <Modal open={isOpen} onClose={onClose} classes={{ root: styles.root }}>
      <Box className={styles.wrapper}>
        <IconButton className={styles.close} onClick={onClose}>
          <IconClose />
        </IconButton>
        <Typography variant="h4_24" component="p" className={styles.title}>
          {t("default.cropModalTitle")}
        </Typography>
        <Box className={styles.cropWrapper} ref={cropRefWrapper}>
          {Boolean(imgSrc) ? (
            <ReactCrop
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => makeClientCrop(c)}
              crop={crop}
              keepSelection={keepSelection}
              locked={locked}
            >
              <img
                src={imgSrc}
                ref={imgRef}
                alt="crop img"
                onLoad={onImageLoad}
              />
            </ReactCrop>
          ) : null}
        </Box>
        {isErrorImgSize ? (
          <Box className={styles.errorBox}>
            <ErrorInfo />
            <Box>
              <Typography
                variant="body3_medium"
                component="p"
                className={styles.errorBoxTitle}
              >
                {t("errors.errorImageSize")}
              </Typography>
              <Typography
                variant="body1"
                className={styles.errorBoxDescription}
              >
                {t("limitedEdition.errorImageSizeDescription")}
              </Typography>
            </Box>
          </Box>
        ) : null}
        {!isErrorImgSize ? (
          <Button
            onClick={submit}
            variant="contain"
            className={styles.submitBtn}
          >
            <Typography component="span" variant="body1_medium">
              {t("default.cropModalTitle")}
            </Typography>
          </Button>
        ) : (
          <>
            <label
              htmlFor="file"
              className={`${styles.uploadLabel} ${styles.submitBtn}`}
            >
              <UploadIcon />
              <Typography component="span" variant="body1_medium">
                {t("default.uploadImage")}
              </Typography>
              <input
                type="file"
                id="file"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    setFileData(file);
                  }
                }}
              />
            </label>
          </>
        )}
      </Box>
    </Modal>
  );
};
