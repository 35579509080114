import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as IconSettings } from "assets/svg/icon-settings.svg";
import Page from "Components/Page/Page";
import { SetupPaymentsSection } from "Components/SetupPaymentsSection/SetupPaymentsSection";
import { FETCH_POSTS_LIMIT, PATHS } from "Constants/index";
import { useEffect } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { setLoader } from "Redux/appSlice";
import { fetchPosts, fetchReactions } from "Redux/communitySlice";
import { getUserMeLoading } from "Redux/selectors/userSelector";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { goToMainAppProfile } from "Utils/mainAppUtils";

import SidebarTablet from "../../Components/SidebarTablet/SidebarTablet";
import styles from "./Community.module.scss";
import { OnboardingList } from "./components/OnboardingList/OnboardingList";
import { Posts } from "./components/Posts";
import { ReviewPosts } from "./components/ReviewPosts/ReviewPosts";
import { StatsSection } from "./components/StatsSection/StatsSection";
import { EyeIcon } from "./icons";

const Community = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userMe = useSelector((state) => state.users.me.entities);
  const userMeLoading = useSelector(getUserMeLoading);
  const {
    entities: { total },
    loading,
  } = useSelector((state) => state.community.posts);

  const {
    entities: { communityGuidelinesAccepted, status },
  } = useSelector((state) => state.users.me);

  useEffect(() => {
    dispatch(fetchPosts({ limit: FETCH_POSTS_LIMIT, page: 1 }));
    dispatch(fetchReactions());
  }, []);

  useEffect(() => {
    dispatch(setLoader(userMeLoading));
  }, [userMeLoading]);

  if (userMeLoading) return <></>;
  return (
    <Page className={styles.clubContainer}>
      <Box className={styles.titleWrap}>
        <SidebarTablet />
        <Typography variant="h2">
          {t("default.myClub")}
          <Button
            className={styles.btnAction}
            variant="text"
            size="small"
            onClick={() => navigate(PATHS.EDIT_COMMUNITY)}
          >
            <IconSettings />
          </Button>
          {total ? (
            <Button
              className={`${styles.btnAction} ${styles.btnActionView}`}
              variant="text"
              size="small"
              onClick={() => goToMainAppProfile({ pathname: userMe.handle })}
            >
              <Typography variant="body2">
                {t("community.viewOnZoop")}
              </Typography>
              <EyeIcon />
            </Button>
          ) : null}
        </Typography>
      </Box>

      <Box className={styles.contentContainer}>
        <Box className={styles.main} style={!total ? { width: "100%" } : {}}>
          {status === "verified" && communityGuidelinesAccepted ? (
            <ReviewPosts />
          ) : null}
          <Posts
            status={status}
            communityGuidelinesAccepted={communityGuidelinesAccepted}
          />
          {!loading ? (
            <OnboardingList
              status={status}
              communityGuidelinesAccepted={communityGuidelinesAccepted}
              postsCount={total}
            />
          ) : null}
        </Box>
        {total ? (
          <Box className={styles.sidebar}>
            <StatsSection />
            <SetupPaymentsSection />
          </Box>
        ) : null}
      </Box>
      <Outlet />
    </Page>
  );
};
export default Community;
