import { Typography } from "@mui/material";
import classNames from "classnames/bind";
import { SetupPaymentsSection } from "Components/SetupPaymentsSection/SetupPaymentsSection";
import {
  PATHS,
  STRIPE_ACCOUNT_STATUS,
  SUBSCRIPTION_SETUP_COMPLETE,
  USER_ME_STATUS,
} from "Constants";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getUsersMeId,
  getUsersMeIsPaymentEnable,
  getUsersMeStatus,
  getUsersMeStripeDataStatus,
} from "Redux/selectors/userSelector";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { OnboardingItem } from "Views/Community/components/OnboardingItem/OnboardingItem";

import OnboardingItemStyles from "../../../Community/components/OnboardingItem/OnboardingItem.module.scss";
import styles from "../../Earnings.module.scss";
import { Onfido } from "./Onfido";

export const Verification = () => {
  const { t } = useContext(LocalizationContext);

  const isPaymentEnable = useSelector(getUsersMeIsPaymentEnable);
  const stripeAccountStatus = useSelector(getUsersMeStripeDataStatus);
  const status = useSelector(getUsersMeStatus);
  const userMeId = useSelector(getUsersMeId);

  const cx = classNames.bind(styles);

  const isShowSetupPayment =
    !isPaymentEnable || stripeAccountStatus !== STRIPE_ACCOUNT_STATUS.ACTIVE;

  const isLastStepDisabled =
    isShowSetupPayment || status !== USER_ME_STATUS.VERIFIED;

  const isShowLastStep =
    userMeId &&
    (isShowSetupPayment ||
      !localStorage.getItem(`${SUBSCRIPTION_SETUP_COMPLETE}_${userMeId}`));

  const navigate = useNavigate();
  const navigateToClubSettings = () => {
    navigate(PATHS.EDIT_COMMUNITY);
  };
  return (
    <>
      {isShowSetupPayment ? (
        <Typography variant="body1" className={styles.subtitle}>
          {t("default.verificationTitle")}
        </Typography>
      ) : null}
      <Onfido />
      <SetupPaymentsSection
        isDisabled={status !== USER_ME_STATUS.VERIFIED}
        title={t("default.paymentsSectionTitle2")}
        classNameContent={cx(OnboardingItemStyles.wrapper, "paymentSection", {
          paymentSectionDisabled: status !== USER_ME_STATUS.VERIFIED,
        })}
        classNameHeader={styles.paymentSectionHeader}
        classNameHeaderWrapper={OnboardingItemStyles.main}
        classNameDescription={styles.paymentSectionDescription}
        isCustomShow={isShowSetupPayment}
      />
      <OnboardingItem
        title={t("default.upgradeClubSubscriptionTitle")}
        description={t("default.upgradeClubSubscriptionTitleDesc")}
        isShowing={isShowLastStep}
        buttonText={t("default.clubSettingsBtn")}
        action={navigateToClubSettings}
        isDisabled={isLastStepDisabled}
      />
    </>
  );
};
