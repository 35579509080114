import { Box, Button, IconButton, Typography } from "@mui/material";
import { ReactComponent as IconBack } from "assets/svg/arrow-left.svg";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { useIsMobile } from "Utils/Hooks";

import { PATHS } from "../../Constants";
import styles from "./KYC.module.scss";

const Step0 = ({ handleNext }) => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const location = useLocation();

  const onBack = () => {
    navigate(location?.state?.prev || PATHS.PROFILE);
  };

  const renderStep = () => {
    return (
      <Box className={[styles.containerCenter, styles.step0]}>
        <Box>
          <Typography variant="h3" component="h1" className={styles.title}>
            {t("kyc.setUpYourAccount")}
          </Typography>
          <Typography variant="textNormal" className={styles.description}>
            {t("kyc.verifyKYCDescription")}
          </Typography>
        </Box>
        <Box className={styles.buttonContainer}>
          <Button variant="contained" onClick={handleNext}>
            {t("kyc.setUpNow")}
          </Button>
          <Button variant="outlined" onClick={onBack}>
            {t("kyc.setUpLater")}
          </Button>
        </Box>
      </Box>
    );
  };
  return (
    <>
      {!isMobile && (
        <IconButton className={styles.back} onClick={onBack}>
          <IconBack />
        </IconButton>
      )}
      {renderStep()}
    </>
  );
};

export default Step0;
