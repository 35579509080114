import { Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { ReactComponent as IconYoutube } from "assets/svg/youtube-icon.svg";
import React, { useEffect } from "react";

import styles from "./YoutubeSection.module.scss";

export const YoutubeSection = ({ isActive, control, errors, setValue }) => {
  useEffect(() => {
    if (!isActive) {
      setValue("youtubeLink", "");
    }
  }, [isActive]);

  if (!isActive) {
    return null;
  }

  return (
    <>
      <FormControl className={styles.wrapper}>
        <TextField
          {...control.register("youtubeLink")}
          placeholder="https://"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconYoutube />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <Typography variant="error">{errors?.youtubeLink?.message}</Typography>
    </>
  );
};
