import { Box, Typography } from "@mui/material";
import { SOCIAL_LINKS } from "Constants";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { getUsersMe } from "../../Redux/selectors/userSelector";
import styles from "./Profile.module.scss";
import SocialMediaLinksItem from "./SocialMediaLinksItem";

const SocialMediaLinks = ({ inputFields, setInputFields, children }) => {
  const { t } = useContext(LocalizationContext);

  const user = useSelector(getUsersMe);
  const socialMediaArr = user?.socialMediaLinks;

  useEffect(() => {
    setInputFields(
      SOCIAL_LINKS.map((item) => {
        const found =
          socialMediaArr &&
          socialMediaArr.length &&
          socialMediaArr.find(
            (val) => val.socialPlatformId === item.socialPlatformId
          );
        return { ...item, link: found?.link || "" };
      })
    );
  }, [socialMediaArr]);

  const handleFormChange = (event, index) => {
    let data = [...inputFields];
    data[index].link = event.target.value;
    setInputFields(data);
  };
  return (
    <Box>
      <Typography variant="subtitle" className={styles.subtitle}>
        {t("account.socialTitle")}
        <Typography className={styles.subtitleDescription} variant="body2">
          {t("account.socialTitleDescription")}
        </Typography>
      </Typography>
      <Box className={`${styles.infoBlock} ${styles.social}`}>
        <Box className={styles.socialList}>
          {inputFields.map((social, index) => {
            return (
              <SocialMediaLinksItem
                className={styles.socialListItem}
                key={index}
                social={social}
                onFormChange={(e) => handleFormChange(e, index)}
              />
            );
          })}
        </Box>
        {children}
      </Box>
    </Box>
  );
};
export default SocialMediaLinks;
