import { Box, CircularProgress, Skeleton, Typography } from "@mui/material";
import Page from "Components/Page/Page";
import SidebarTablet from "Components/SidebarTablet/SidebarTablet";
import {
  PATHS,
  SCROLL_THRESHOLD,
  STRIPE_ACCOUNT_STATUS,
  SUBSCRIPTION_SETUP_COMPLETE,
  USER_ME_STATUS,
} from "Constants/index";
import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setError, setLoader } from "Redux/appSlice";
import { errorResponseMessages } from "Services/Api/apiErrorHelper.tsx";
import { getEarnings } from "Services/Api/donationsApi";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import {
  getUserMeLoading,
  getUserMeSubscriptionPrice,
  getUsersMeId,
  getUsersMeIsPaymentEnable,
  getUsersMeStatus,
  getUsersMeStripeDataStatus,
} from "../../Redux/selectors/userSelector";
import { CollapsibleBox } from "./components/CollapsibleBox/CollapsibleBox";
import { SumBoxList } from "./components/SumBoxList/SumBoxList";
import { Verification } from "./components/Verification/Verification";
import styles from "./Earnings.module.scss";

const initialState = {
  monthlyAverage: 0,
  allTimeEarnings: 0,
  monthlyEarnings: null,
};

const LIMIT = 40;

export const Earnings = () => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);

  const status = useSelector(getUsersMeStatus);
  const userMeLoading = useSelector(getUserMeLoading);
  const userMeId = useSelector(getUsersMeId);

  const [loader, setLocalLoader] = useState(true);
  const [earningsData, setEarningsData] = useState(initialState);
  const [page, setPage] = useState(1);
  const subscriptionPrice = useSelector(getUserMeSubscriptionPrice);

  const loadMore = () => setPage((prevState) => prevState + 1);

  const dataLength = earningsData?.monthlyEarnings
    ? Object.keys(earningsData?.monthlyEarnings)?.length
    : 0;

  const hasMore = dataLength === LIMIT * page;

  useEffect(() => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    if (status === USER_ME_STATUS.PROFILE_COMPLETED && params.has("payment")) {
      console.log(params);
    }
  }, [status]);

  useEffect(() => {
    getEarnings({ page, limit: LIMIT })
      .then((data) => {
        if (data) {
          setEarningsData((prevState) => ({
            ...data,
            monthlyEarnings: {
              ...prevState.monthlyEarnings,
              ...data?.monthlyEarnings,
            },
          }));
        }
      })
      .catch((e) => {
        dispatch(
          setError({
            open: true,
            title: t("errors.error"),
            subtitle: errorResponseMessages(e, t),
          })
        );
      })
      .finally(() => setLocalLoader(false));
  }, [page]);

  useEffect(() => {
    if (userMeLoading && !userMeId) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
  }, [userMeLoading, userMeId]);

  const isPaymentEnable = useSelector(getUsersMeIsPaymentEnable);
  const stripeAccountStatus = useSelector(getUsersMeStripeDataStatus);
  const isShowSetupPayment =
    !isPaymentEnable || stripeAccountStatus !== STRIPE_ACCOUNT_STATUS.ACTIVE;

  const showEarnings =
    (localStorage.getItem(`${SUBSCRIPTION_SETUP_COMPLETE}_${userMeId}`) ||
      dataLength ||
      subscriptionPrice) &&
    !isShowSetupPayment;

  return (
    <Page>
      <Box className={styles.titleWrap}>
        <SidebarTablet />
        <Typography variant="h2" className={styles.title}>
          {t("default.earnings")}
        </Typography>
      </Box>
      {showEarnings || !userMeId ? (
        <>
          <SumBoxList
            monthlyAverage={earningsData.monthlyAverage}
            allTimeEarnings={earningsData.allTimeEarnings}
          />
          <Typography variant="body2" className={styles.subtitle}>
            {t("default.earningsDesc")}{" "}
            <Link to={PATHS.EDIT_COMMUNITY} className={styles.link}>
              {t("default.clubSettings")}
            </Link>
            .
          </Typography>
          {loader && !earningsData?.monthlyEarnings ? (
            <Skeleton
              variant="rectangular"
              style={{ height: "300px", borderRadius: "10px" }}
            />
          ) : null}
          <InfiniteScroll
            next={loadMore}
            dataLength={dataLength}
            hasMore={hasMore}
            scrollableTarget="scrollableDiv"
            scrollThreshold={SCROLL_THRESHOLD}
            loader={
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            }
          >
            <CollapsibleBox
              data={earningsData.monthlyEarnings}
              loader={loader}
            />
          </InfiniteScroll>
        </>
      ) : (
        <Verification />
      )}
    </Page>
  );
};
