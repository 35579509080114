import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { FileThumb } from "Components/FileThumb/FileThumb";
import {
  AUDIO_FORMAT,
  IMAGE_FORMAT,
  MAX_ALL_FILES_SIZE,
  VIDEO_FORMAT,
} from "Constants/index";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { setSnackbar } from "Redux/appSlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { UploadIcon } from "../../../../icons";
import { AddMoreIcon } from "../../icons";
import styles from "./FileUploader.module.scss";

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "8px",
  padding: "40px 5px",
  cursor: "pointer",
  borderRadius: "10px",
  outline: "none",
  border: "1px solid rgba(80, 90, 169, 0.5)",
  background: "rgba(33, 38, 78, 0.5)",
  transition: "border .24s ease-in-out",
};

const acceptStyle = {
  opacity: 0.5,
};

const rejectStyle = {
  borderColor: "#ee5261",
};

const maxFiles = 24;
const maxSize = MAX_ALL_FILES_SIZE;

export const FileUploader = ({
  files,
  setFiles,
  isActive,
  emptySubTitle,
  emptyTitle,
  disabled,
}) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      maxFiles,
      maxSize,
      accept: {
        "image/*": Object.values(IMAGE_FORMAT).map((item) => `.${item}`),
        "video/*": Object.values(VIDEO_FORMAT).map((item) => `.${item}`),
        "audio/*": Object.values(AUDIO_FORMAT).map((item) => `.${item}`),
      },
      onDrop: (acceptedFiles, d) => {
        const tooManyFiles = acceptedFiles.length + files.length > maxFiles;
        if (tooManyFiles) {
          dispatch(
            setSnackbar({
              open: true,
              message: t("limitedEdition.maxFilesError", { maxFiles }),
              severity: "error",
            })
          );
        } else {
          //ToDO: hot solution
          setLoader(true);
          setTimeout(() => {
            setFiles((prevState) =>
              [...prevState, ...acceptedFiles].map((file) => {
                return file.cardId
                  ? file
                  : Object.assign(file, {
                      preview: URL.createObjectURL(file),
                    });
              })
            );
          }, 100);
          setTimeout(() => setLoader(false), 1500);
        }
      },
      onDropRejected: (rejectedFiles) => {
        const tooManyFiles = rejectedFiles.length > maxFiles;
        const tooLargeFiles = rejectedFiles.some(
          (item) => item.file?.size > maxSize
        );
        if (tooManyFiles) {
          dispatch(
            setSnackbar({
              open: true,
              message: t("limitedEdition.maxFilesError", { maxFiles }),
              severity: "error",
            })
          );
        } else if (tooLargeFiles) {
          dispatch(
            setSnackbar({
              open: true,
              message: t("limitedEdition.sizeFilesError", {
                maxSize: `${maxSize}mb`,
              }),
              severity: "error",
            })
          );
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: t("limitedEdition.filesRejected"),
              severity: "error",
            })
          );
        }
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragAccept, isDragReject]
  );

  const handleDeleteFile = (fileToDelete) => () => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
    URL.revokeObjectURL(fileToDelete.preview);
  };

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <Box className={isActive ? styles.uploaderActive : styles.uploader}>
      {loader ? (
        <Box className={styles.loader}>
          <CircularProgress />
        </Box>
      ) : null}
      {files.length ? (
        <Box className={styles.thumbs}>
          {files.map((file) => (
            <FileThumb
              file={file}
              handleDeleteFile={handleDeleteFile}
              key={file.path}
            />
          ))}
          <Button
            {...getRootProps()}
            variant="outlined"
            className={styles.addMore}
            disabled={files.length >= 24 || disabled}
          >
            <input {...getInputProps()} disabled={disabled} />
            <AddMoreIcon />
            <Typography variant="body3_medium_14_display">
              {t("default.addMore")}
            </Typography>
          </Button>
        </Box>
      ) : (
        <Box {...getRootProps({ style, className: styles.dropzone })}>
          <input {...getInputProps()} disabled={disabled} />
          <UploadIcon />
          <Typography variant="body5">{emptyTitle}</Typography>
          <Typography className="opacity_07" variant="body3">
            {emptySubTitle}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
