import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  DATE_LOCALES,
  LANGUAGES_LIST,
  WEBSITE_LANG_STRINGS,
  zoopPolicyHost,
} from "Constants/index";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export function useIsMobile() {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.down("lg"));
}

export function useBreakpoint(value) {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.down(value));
}

export function useIsTablet(
  tabletBreakpointS = "768px",
  tabletBreakpoint = "1280px"
) {
  const isTablet = useMediaQuery(
    `(min-width: ${tabletBreakpointS}) and (max-width: ${tabletBreakpoint})`
  );

  return isTablet;
}

export function useIsLessThan1536() {
  return useMediaQuery("(max-width: 1536px)");
}

export function useRedirectAfterAuth() {
  const navigate = useNavigate();
  let redirect = useCallback(
    function (defaultPath = "/") {
      const from = localStorage.getItem("from") || defaultPath;
      localStorage.removeItem("from");
      navigate(from, { replace: true });
    },
    [navigate]
  );
  return redirect;
}

export function useDidUpdateEffect(effect, deps) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
    } else {
      return effect();
    }
  }, deps);
}
//TODO::it is better to use ViewportContext instead of useWindowSize
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener("resize", _.debounce(handleResize, 200));
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export function useLocale() {
  const user = useSelector((state) => state.mainAppUser.entities);
  return DATE_LOCALES[user?.language || LANGUAGES_LIST.english];
}

export const usePolicyHostUrl = () => {
  const user = useSelector((state) => state.mainAppUser.entities);

  let hostUrl = useCallback(
    (page) => {
      const langFormatted =
        WEBSITE_LANG_STRINGS[user.language] ||
        WEBSITE_LANG_STRINGS[LANGUAGES_LIST.english];
      return `${zoopPolicyHost}/${langFormatted}/${page}`;
    },
    [user.language]
  );

  return hostUrl;
};
