export const ProfileIcon = () => (
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.82513 5.62005C8.28513 6.16006 7.53512 6.50006 6.70511 6.50006C5.0451 6.50006 3.70508 5.16005 3.70508 3.50003C3.70508 1.84001 5.0451 0.5 6.70511 0.5C8.36513 0.5 9.70514 1.84001 9.70514 3.50003M1.70507 13.5C-0.794955 6.49993 3.94509 9.49996 6.70512 9.49996C9.46515 9.49996 14.2052 6.49993 11.7052 13.5"
      stroke="white"
      strokeWidth="0.8"
      strokeLinejoin="round"
    />
  </svg>
);

export const BlockProfileIcon = () => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.60908 8.06284C7.20467 8.46725 6.64439 8.72075 6.02473 8.72075V9.52074C6.86712 9.52074 7.62796 9.17533 8.17477 8.62853L7.60908 8.06284ZM6.02473 8.72075C4.7836 8.72075 3.78248 7.71963 3.78248 6.47849H2.98248C2.98248 8.16145 4.34177 9.52074 6.02473 9.52074V8.72075ZM3.78248 6.47849C3.78248 5.23736 4.7836 4.23624 6.02473 4.23624V3.43624C4.34177 3.43624 2.98248 4.79553 2.98248 6.47849H3.78248ZM6.02473 4.23624C7.26587 4.23624 8.26699 5.23736 8.26699 6.47849H9.06699C9.06699 4.79553 7.70769 3.43624 6.02473 3.43624V4.23624ZM1.99768 15.1514C1.44947 13.6164 1.33344 12.7152 1.43104 12.2104C1.47661 11.9747 1.56441 11.8484 1.65813 11.7722C1.75775 11.6912 1.90593 11.633 2.12643 11.6103C2.58682 11.5631 3.20111 11.6823 3.91681 11.8373C4.59707 11.9847 5.36381 12.1629 6.02474 12.1629V11.3629C5.47023 11.3629 4.80355 11.2108 4.08617 11.0554C3.40423 10.9077 2.65665 10.7517 2.04473 10.8145C1.72907 10.8469 1.41349 10.94 1.15344 11.1515C0.8875 11.3677 0.71974 11.675 0.645585 12.0585C0.503734 12.7922 0.691563 13.8728 1.24429 15.4204L1.99768 15.1514ZM6.02474 12.1629C6.68567 12.1629 7.45241 11.9847 8.13267 11.8373C8.84837 11.6823 9.46266 11.5631 9.92304 11.6103C10.1435 11.633 10.2917 11.6912 10.3913 11.7722C10.4851 11.8484 10.5729 11.9747 10.6184 12.2104C10.716 12.7152 10.6 13.6164 10.0518 15.1514L10.8052 15.4204C11.3579 13.8728 11.5457 12.7922 11.4039 12.0585C11.3297 11.675 11.162 11.3677 10.896 11.1515C10.636 10.94 10.3204 10.8469 10.0048 10.8145C9.39282 10.7517 8.64525 10.9077 7.9633 11.0554C7.24593 11.2108 6.57925 11.3629 6.02474 11.3629V12.1629ZM11.7237 1.87287L10.2828 0.432001L9.71716 0.997686L11.158 2.43856L11.7237 1.87287ZM11.158 1.87287L9.71716 3.31375L10.2828 3.87943L11.7237 2.43856L11.158 1.87287ZM11.158 2.43856L12.5989 3.87943L13.1646 3.31375L11.7237 1.87287L11.158 2.43856ZM11.7237 2.43856L13.1646 0.997686L12.5989 0.432001L11.158 1.87287L11.7237 2.43856Z"
      fill="white"
    />
  </svg>
);

export const ReportIcon = () => (
  <svg
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.13994 10.6912L0.782258 10.4875L1.13994 10.6912ZM13.6649 10.6912L14.0226 10.4875L13.6649 10.6912ZM8.29708 1.26212L7.9394 1.46574L8.29708 1.26212ZM6.5078 1.26212L6.86548 1.46574L6.5078 1.26212ZM6.99085 9.97698C6.99085 10.2043 7.17513 10.3886 7.40244 10.3886C7.62975 10.3886 7.81402 10.2043 7.81402 9.97698H6.99085ZM7.81402 9.84253C7.81402 9.61522 7.62975 9.43094 7.40244 9.43094C7.17513 9.43094 6.99085 9.61522 6.99085 9.84253H7.81402ZM7.9394 1.46574L13.3073 10.8948L14.0226 10.4875L8.65477 1.05849L7.9394 1.46574ZM12.7703 11.8511H2.03458V12.6743H12.7703V11.8511ZM1.49763 10.8948L6.86548 1.46574L6.15011 1.05849L0.782258 10.4875L1.49763 10.8948ZM2.03458 11.8511C1.56499 11.8511 1.25128 11.3275 1.49763 10.8948L0.782258 10.4875C0.233365 11.4517 0.913707 12.6743 2.03458 12.6743V11.8511ZM13.3073 10.8948C13.5536 11.3275 13.2399 11.8511 12.7703 11.8511V12.6743C13.8912 12.6743 14.5715 11.4517 14.0226 10.4875L13.3073 10.8948ZM8.65477 1.05849C8.0993 0.0827653 6.70558 0.0827633 6.15011 1.05849L6.86548 1.46574C7.10525 1.04457 7.69963 1.04457 7.9394 1.46574L8.65477 1.05849ZM6.99085 3.9395V8.79895H7.81402V3.9395H6.99085ZM7.81402 9.97698V9.84253H6.99085V9.97698H7.81402Z"
      fill="white"
    />
  </svg>
);

export const DeleteIcon = () => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_31984_5749)">
      <path
        d="M10.9344 6.14555V12.0734C10.9344 12.8593 10.3127 13.5 9.55038 13.5H4.3891C3.62673 13.5 3.0051 12.8593 3.0051 12.0734V6.14555M9.13889 3.07476H12.5V4.72826H1.5V3.07476H4.86111M9.13889 3.07476V2.44486C9.13889 2.1299 8.95556 1.5 8.22222 1.5C7.48889 1.5 6.33796 1.5 5.77778 1.5C5.47222 1.5 4.86111 1.68897 4.86111 2.44486C4.86111 3.20074 4.86111 2.86479 4.86111 3.07476M9.13889 3.07476H7H4.86111M7 6.93293V11.6572M8.83333 7.87779V10.0825M5.16667 7.87779V10.0825"
        stroke="#EE5261"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_31984_5749">
        <rect
          width="14"
          height="14"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const ArrowLeftIcon = () => (
  <svg
    width="19"
    height="25"
    viewBox="0 0 19 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 6.5L1 12.5L7 18.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MailIcon = () => (
  <svg
    width="60"
    height="48"
    viewBox="0 0 60 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 6.91127C1 5.40049 1.61107 3.95158 2.69878 2.88329C3.78649 1.815 5.26174 1.21484 6.8 1.21484H53.2C54.7383 1.21484 56.2135 1.815 57.3012 2.88329C58.3889 3.95158 59 5.40049 59 6.91127V41.0898C59 42.6006 58.3889 44.0495 57.3012 45.1178C56.2135 46.1861 54.7383 46.7863 53.2 46.7863H6.8C5.26174 46.7863 3.78649 46.1861 2.69878 45.1178C1.61107 44.0495 1 42.6006 1 41.0898V6.91127Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 10.5352L22.7529 27.6273C24.8098 29.2437 27.3657 30.1243 30 30.1243C32.6343 30.1243 35.1902 29.2437 37.2471 27.6273L59 10.5352"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const InfoIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 19.5C14.9706 19.5 19 15.4706 19 10.5C19 5.52944 14.9706 1.5 10 1.5C5.02944 1.5 1 5.52944 1 10.5C1 15.4706 5.02944 19.5 10 19.5Z"
      stroke="white"
      strokeOpacity="0.7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M10 5.5V13.09"
      stroke="white"
      strokeOpacity="0.7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M10 14.9288V14.7188"
      stroke="white"
      strokeOpacity="0.7"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SeenIcon = () => (
  <svg
    width="17"
    height="9"
    viewBox="0 0 17 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 5.2L3.8 8L10.8 1"
      stroke="#70E8FC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.1969 1L9.19688 8L7.79688 6.6"
      stroke="#70E8FC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DeliveredTickIcon = () => (
  <svg
    width="17"
    height="9"
    viewBox="0 0 17 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.7">
      <path
        d="M1 5.2L3.8 8L10.8 1"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1969 1L9.19688 8L7.79688 6.6"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export const SendTickIcon = () => (
  <svg
    width="11"
    height="9"
    viewBox="0 0 11 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.7"
      d="M0.703125 5.2L3.50313 8L10.5031 1"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
