import { createSelector } from "@reduxjs/toolkit";
import { orderBy } from "lodash";

export const getSelectedUser = (state) => state.messenger.selectedUser;
export const getLoadingByCreateChat = (state) =>
  state.messenger.isLoadingByChatCreate;
export const getGlobalCountUnreadMessages = (state) =>
  state.messenger.globalAmountUnreadMessages;
export const getSingleChat = (id, type) => (state) =>
  state.messenger.chats[type][id];
export const getSingleRequestsChat = (id) => (state) =>
  state.messenger.requestsChats[id];
export const getTotalChats = (type) => (state) =>
  state.messenger.totalChats[type];
export const getActiveChat = (state) => state.messenger.activeChat;
export const getDeletedChatId = (state) => state.messenger.deletedChatId;
export const getTotalRequests = (state) => state.messenger.totalRequests;
export const getChatsLoading = (state) => state.messenger.chatsLoader;
export const getRequestsChatsLoading = (state) =>
  state.messenger.chatsRequestsLoader;
export const getHasMoreChats = (type) => (state) =>
  state.messenger.hasMoreChats[type];
export const getHasMoreRequestsChats = (state) =>
  state.messenger.hasMoreRequestsChats;
export const getSingleChatsLoader = (state) =>
  state.messenger.singleChatsLoader;

// get chats
const chats = (state) => state.messenger.chats;
export const getChats = (type) =>
  createSelector(chats, (data) =>
    orderBy(
      Object.values(data[type]),
      [(chat) => chat.lastMessage?.timestamp || chat.updatedAt],
      "desc"
    )
  );

// get requested chats
export const requestsChats = (state) => state.messenger.requestsChats;
export const getRequestsChats = createSelector(requestsChats, (data) =>
  orderBy(
    Object.values(data),
    [(chat) => chat.lastMessage?.timestamp || chat.updatedAt],
    "desc"
  )
);

// get messages by selected chat
const selectSingleChats = (state) => state.messenger.singleChats;
export const getActiveMessagesData = (id) =>
  createSelector([selectSingleChats, () => id], (singleChats, id) => {
    if (singleChats[id]?.messages) {
      return {
        messages: orderBy(Object.values(singleChats[id].messages), "id"),
        total: singleChats[id].total,
      };
    }
    return {
      messages: [],
      total: 0,
    };
  });
