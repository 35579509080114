import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import parse from "autosuggest-highlight/parse";
import classnames from "classnames/bind";
import * as React from "react";
import roundedInputStyles from "styles/input/rounded.module.scss";
import theme from "theme/theme";
import { transliterate } from "transliteration";
import { parcePlaceDetails } from "Utils/placesHelper";
import { transliterateToAlphaNum } from "Utils/StringHelper";

import styles from "./KYC.module.scss";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const placeService = { current: null };

export default function PlaceAutocomplete({
  defaultValue,
  error,
  errorText,
  placeholder,
  onChange,
  onOptionPress,
  rounded,
  label,
  disabled,
}) {
  const cx = classnames.bind(styles);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&language=en`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, (results) => {
          if (results) {
            const transliteratedResults = results.map((result) => ({
              ...result,
              description: transliterate(result.description),
              structured_formatting: {
                ...result.structured_formatting,
                main_text: transliterate(
                  result.structured_formatting.main_text
                ),
                secondary_text: transliterate(
                  result.structured_formatting.secondary_text
                ),
              },
              terms: result.terms.map((item) => ({
                ...item,
                value: transliterate(item.value),
              })),
            }));
            callback(transliteratedResults);
          } else {
            callback([]);
          }
        });
      }, 400),
    []
  );

  const fetchPlaceDetails = (placeId, callback) => {
    placeService.current.getDetails({ placeId: placeId }, callback);
  };

  const handleOptionPress = async (placeId) => {
    fetchPlaceDetails(placeId, (results) => {
      const details = parcePlaceDetails(results);
      onOptionPress(details);
    });
  };

  React.useEffect(() => {
    onChange(inputValue || "");
  }, [inputValue]);

  React.useEffect(() => {
    let active = true;
    if (
      !autocompleteService.current &&
      !placeService.current &&
      window.google
    ) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
      placeService.current = new window.google.maps.places.PlacesService(
        inputRef.current
      );
    }
    if (!autocompleteService.current && !placeService.current) {
      return;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return;
    }

    fetch({ input: inputValue, types: ["address"] }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        } else {
          newOptions = [];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleValueChange = (newValue) => {
    setValue(newValue);
    if (newValue?.place_id) {
      handleOptionPress(newValue?.place_id);
    }
  };

  const roundedListStyles =
    rounded && !!options.length
      ? {
          border: `1px solid ${theme.palette.purple.secondary}`,
          borderRadius: "0 0 10px 10px",
          pt: "8px",
          mt: "-8px",
        }
      : null;

  return (
    <Autocomplete
      className={rounded ? roundedInputStyles.inputWrap : styles.inputWrap}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      inputValue={inputValue}
      freeSolo
      componentsProps={{
        paper: {
          sx: {
            backgroundColor: "#111322",
            maxHeight: "320px",
            ...roundedListStyles,
          },
          variant: "outlined",
        },
      }}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        handleValueChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(transliterateToAlphaNum(newInputValue));
      }}
      renderInput={(params) => (
        <Box>
          <TextField
            {...params}
            label={rounded ? label : ""}
            placeholder={rounded ? null : placeholder || ""}
            variant="standard"
            className={`${
              rounded
                ? cx(roundedInputStyles.input, {
                    [roundedInputStyles.filled]: !!inputValue?.length,
                  })
                : cx(styles.input, {
                    [styles.error]: error,
                  })
            }`}
            error={error}
          />
          <TextField
            label={""}
            placeholder={""}
            ref={inputRef}
            variant="standard"
            style={{
              position: "absolute",
              display: "none",
              right: "-9999px",
            }}
          />
          <Typography variant="error">{errorText}</Typography>
        </Box>
      )}
      renderOption={(props, option) => {
        const matches =
          option?.structured_formatting?.main_text_matched_substrings || [];
        const parts =
          matches.length > 0
            ? parse(
                option?.structured_formatting?.main_text,
                matches.map((match) => [
                  match.offset,
                  match.offset + match.length,
                ])
              )
            : [];

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                {parts.map((part, index) => (
                  <Typography variant="textMedium" key={index}>
                    {part?.text}
                  </Typography>
                ))}

                <Typography
                  variant="body2"
                  className={styles.placeSecondaryText}
                >
                  {option?.structured_formatting?.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      disabled={disabled}
    />
  );
}
