import * as cheerio from "cheerio";

export const parseLinks = (htmlString) => {
  if (htmlString) {
    const $ = cheerio.load(htmlString);
    $("a[href]").each((i, el) => {
      const href = $(el).attr("href");
      if (href && !/^https?:\/\//i.test(href)) {
        $(el).attr("href", "https://" + href);
      }
    });
    return $.html();
  }
};
