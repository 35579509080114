import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";

import styles from "./TypesErrorBox.module.scss";

const IconInfo = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
      stroke="#F53F4B"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M11 6.20898V12.534"
      stroke="#F53F4B"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 15.8039V15.6289"
      stroke="#F53F4B"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TypesErrorBox = ({ message, isError, setErrorType }) => {
  const theme = useTheme();

  useEffect(() => {
    setTimeout(() => setErrorType(false), 5000);
  }, [isError]);

  if (!isError) {
    return null;
  }

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.errorBox}>
        <IconInfo />
        <Typography variant="body1" color={theme.palette.error.main}>
          {message}
        </Typography>
      </Box>
    </Box>
  );
};
