import { useEffect, useState } from "react";

export const useShowWithDelay = ({
  checkDelay = 2000,
  targetDate,
  minutes,
}) => {
  const [isShow, setShow] = useState(false);

  const isWithinMinutes = ({ targetDate, minutes }) => {
    const now = new Date();
    const target = new Date(targetDate);
    const milliseconds = minutes * 60 * 1000; // Convert minutes to milliseconds

    // Calculate the absolute difference in milliseconds
    const diff = Math.abs(now - target);

    // Check if the difference is less than or equal to the specified number of minutes
    return diff <= milliseconds;
  };

  useEffect(() => {
    let interval;
    if (isShow) {
      clearInterval(interval);
    }
    if (!isShow && targetDate) {
      interval = setInterval(() => {
        const isCallSoon = isWithinMinutes({
          targetDate,
          minutes,
        });
        if (isCallSoon) {
          setShow(true);
        }
      }, checkDelay);
    }
    return () => clearInterval(interval);
  }, [isShow, targetDate]);

  return isShow;
};
