import { useEffect, useRef } from "react";

const useDelayedExecution = (delay) => {
  const timeoutRef = useRef(null);

  const startExecute = (callback) => {
    timeoutRef.current = setTimeout(() => {
      callback();
    }, delay);
  };

  const stopExecute = () => {
    clearTimeout(timeoutRef.current);
  };

  useEffect(() => {
    return () => {
      stopExecute();
    };
  }, []);

  return { startExecute, stopExecute };
};

export default useDelayedExecution;
