import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/svg/icon-close.svg";
import { ReactComponent as NotificationIcon } from "assets/svg/notification-icon.svg";

import styles from "./CustomAlert.module.scss";

export const CustomAlert = ({
  severity,
  title,
  classNameWrapper,
  classNameTitle,
  isShow = true,
  onClose,
  children,
}) => {
  if (!isShow) {
    return null;
  }

  return (
    <Box
      className={`${styles.wrapper} ${
        classNameWrapper ? classNameWrapper : ""
      } ${onClose ? styles.wrapperClose : ""}`}
    >
      {severity === "info" ? <NotificationIcon /> : null}
      <Typography
        variant="body1"
        className={`${styles.title} ${classNameTitle ? classNameTitle : ""}`}
      >
        {title}
      </Typography>
      {children}
      {onClose ? (
        <IconButton onClick={onClose} className={styles.close}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </Box>
  );
};
