import {
  KYC_ERROR_STATUS,
  KYC_STATUS,
  PATHS,
  USER_ME_STATUS,
} from "Constants/index";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setOnfidoToken } from "Redux/appSlice";
import { fetchUser } from "Redux/mainAppUserSlice";
import {
  getUserMeLoading,
  getUsersMeCommunityGuidelinesAccepted,
  getUsersMeId,
  getUsersMeStatus,
} from "Redux/selectors/userSelector";
import { fetchUserMe } from "Redux/usersSlice";
import { getKycStatus } from "Services/Api/mainAppApi";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { CheckKycStatus } from "Utils/Hooks/kycCheck";

import { OnboardingItem } from "../../../Community/components/OnboardingItem/OnboardingItem";
export const Onfido = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onfido = useSelector((state) => state.mainAppUser?.onfido);
  const userMeId = useSelector(getUsersMeId);
  const userMeLoading = useSelector(getUserMeLoading);
  const status = useSelector(getUsersMeStatus);
  const communityGuidelinesAccepted = useSelector(
    getUsersMeCommunityGuidelinesAccepted
  );

  const [processing, setProcessing] = useState(false);
  const [kycStatus, setKycStatus] = useState(null);
  const [kycStatusLoading, setKycStatusLoading] = useState(false);

  const checkStatus = CheckKycStatus();

  const handleOnfido = async () => {
    setProcessing(true);
    const status = await checkStatus();
    if (status?.sdkToken) {
      setProcessing(false);
      return dispatch(
        setOnfidoToken({
          sdkToken: status?.sdkToken,
        })
      );
    }
    if (status !== 1) {
      setProcessing(false);
    }
  };

  const goToSupport = () => {
    window.zE("messenger:set", "zIndex", 99999);
    window.zE("messenger", "open");
  };

  const fidoHandle = (kycStatus) => () => {
    if (status === USER_ME_STATUS.PROFILE_COMPLETED) {
      if (KYC_ERROR_STATUS[kycStatus?.toUpperCase()]) {
        goToSupport();
      } else {
        handleOnfido();
      }
    } else {
      navigate(PATHS.COMPLETE_PROFILE);
    }
  };

  useEffect(() => {
    dispatch(fetchUser());
    dispatch(fetchUserMe()).then(() => {
      setKycStatusLoading(true);
      getKycStatus()
        .then((data) => {
          if (
            data.data.state === KYC_STATUS.ACCEPT &&
            kycStatus !== KYC_STATUS.ACCEPT
          ) {
            // dispatch(
            //   setSnackbar({
            //     open: true,
            //     message: t("community.identityVerificationSuccessful"),
            //     right: "20px",
            //     left: "unset",
            //   })
            // );
          }
          setKycStatus(data.data.state);
        })
        .finally(() => setKycStatusLoading(false));
    });
  }, [onfido.status]);

  useEffect(() => {
    if (
      status === USER_ME_STATUS.PROFILE_COMPLETED &&
      !sessionStorage.getItem(`onfido-run-${userMeId}`)
    ) {
      handleOnfido().finally(() => {
        sessionStorage.setItem(`onfido-run-${userMeId}`, "true");
      });
    }
  }, [status]);

  const getKycText = (currentStatus) => {
    switch (currentStatus) {
      case KYC_STATUS.IN_PROGRESS:
        return {
          title: t("community.oboarding1TitleInProgress"),
          description: t("community.oboarding1DescriptionInProgress"),
          buttonText: t("community.oboarding1ActionTextInProgress"),
        };
      case KYC_STATUS.REJECTED:
      case KYC_STATUS.DENY:
      case KYC_STATUS.SUSPECTED:
      case KYC_STATUS.CAUTION:
        return {
          title: t("community.oboarding1TitleError"),
          description: t("community.oboarding1DescriptionError"),
          buttonText: t("community.oboarding1ActionTextError"),
        };
      default:
        return {
          title: t("community.oboarding1Title"),
          description: t("community.oboarding1Description"),
          buttonText: t("community.oboarding1ActionText"),
        };
    }
  };
  if (!userMeId) return null;
  return (
    <OnboardingItem
      title={getKycText(kycStatus).title}
      description={getKycText(kycStatus).description}
      isShowing={status !== "verified"}
      buttonText={getKycText(kycStatus).buttonText}
      action={fidoHandle(kycStatus)}
      isLoading={processing || userMeLoading || kycStatusLoading}
      status={kycStatus}
      isDisabled={!communityGuidelinesAccepted}
    />
  );
};
