import { Box, ListItemButton } from "@mui/material";
import React from "react";

import styles from "./PopoverContainer.module.scss";

export const PopoverContainer = ({
  loading,
  data,
  selectedReaction,
  handleSelect,
}) => {
  return (
    <Box className={styles.reactionsPopoverContainer}>
      {loading ? (
        <></>
      ) : (
        data.map((item) => {
          const isSelected = selectedReaction.find(
            (reaction) => reaction?.name === item.name
          );
          return (
            <ListItemButton
              key={item.id}
              selected={isSelected}
              onClick={(e) => handleSelect(e, item, isSelected)}
              className={styles.reactionsPopoverItem}
            >
              <img src={item.imageUrl} alt={item.emotion} />
            </ListItemButton>
          );
        })
      )}
    </Box>
  );
};
