import { Box, Typography } from "@mui/material";

import styles from "./Gallery.module.scss";

const Gallery = ({ images, onImgClick }) => {
  if (!images?.length) return null;
  const handleClick = (e, img) => {
    onImgClick(e, img);
  };
  return (
    <Box className={styles.gallery}>
      <Box
        className={`${styles.gridGalleyBig} ${
          images.length === 1 ? styles.gridGalleyOne : ""
        }`}
      >
        {images.slice(0, 2).map((image, index) => {
          return (
            <img
              className={styles.gridGalleyItem}
              src={image}
              alt="gallery-item"
              key={`gallery-item-big-${index}`}
              onClick={(e) => handleClick(e, image)}
            />
          );
        })}
      </Box>
      {images.length > 2 ? (
        <Box
          className={styles.gridGalleySmall}
          sx={{
            gridTemplateColumns: `repeat(${images.slice(2, 5).length}, 1fr)`,
          }}
        >
          {images.slice(2, 5).map((image, index) => {
            if (index === 2 && images.length > 5) {
              return (
                <Box
                  className={styles.galleryMore}
                  key={`gallery-item-${index}`}
                  onClick={(e) => handleClick(e, image)}
                >
                  <Typography variant="h4" className={styles.galleryMoreText}>
                    +{images.length - 5}
                  </Typography>
                  <img
                    className={styles.gridGalleyItem}
                    src={image}
                    alt="gallery-item"
                  />
                </Box>
              );
            }
            return (
              <img
                className={styles.gridGalleyItem}
                src={image}
                key={`gallery-item-${index}`}
                alt="gallery-item"
                onClick={(e) => handleClick(e, image)}
              />
            );
          })}
        </Box>
      ) : null}
    </Box>
  );
};
export default Gallery;
