import { Typography } from "@mui/material";
import classNames from "classnames/bind";
import React from "react";

import styles from "./Profile.module.scss";

export const FormErrorMessage = ({ isError, message, className }) => {
  if (!isError) {
    return null;
  }
  const cx = classNames.bind(styles);
  return (
    <Typography variant="error" className={cx(styles.error, className)}>
      {message}
    </Typography>
  );
};
