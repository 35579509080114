export const parcePlaceDetails = (details) => {
  if (!details) return null;
  const addressComponent = details.address_components;
  const result = {};

  for (let i = 0; i < addressComponent.length; i++) {
    const types = addressComponent[i].types;

    if (types.includes("locality")) {
      result.city = addressComponent[i].long_name || "";
    }

    if (types.includes("street_number")) {
      result.streetNumber = addressComponent[i].long_name || "";
    }

    if (types.includes("route")) {
      result.streetAddress = addressComponent[i].long_name || "";
    }

    if (types.includes("administrative_area_level_1")) {
      result.region = addressComponent[i].short_name || "";
    }

    if (types.includes("country")) {
      result.country = addressComponent[i].long_name || "";
    }
  }

  result.detailedAddress = `${result.streetNumber || ""} ${
    result.streetAddress || ""
  } ${result.city || ""} ${result.region || ""}`;

  return result;
};
