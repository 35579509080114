const colors = [
  "#111322",
  "#111532",
  "#21264e",
  "#181c38",
  "#3d4bb5",
  "#abb3f3",
  "#ffffff",
  "#ee5261",
  "#f1fc70",
  "#3d4bb5",
  "#abb3f3",
  "transparent",
];
const formats = [
  [
    {
      className: "ql-size",
      options: ["small", "large", "huge"],
    },
  ],
  [
    { className: "ql-bold" },
    { className: "ql-italic" },
    { className: "ql-underline" },
    { className: "ql-strike" },
  ],
  [
    {
      className: "ql-color",
      options: colors,
    },
    {
      className: "ql-background",
      options: colors,
    },
  ],
  [
    {
      className: "ql-script",
      value: "sub",
    },
    {
      className: "ql-script",
      value: "super",
    },
  ],
  [
    {
      className: "ql-header",
      value: "1",
    },
    {
      className: "ql-header",
      value: "2",
    },
    {
      className: "ql-blockquote",
    },
  ],
  [
    {
      className: "ql-list",
      value: "ordered",
    },
    {
      className: "ql-list",
      value: "bullet",
    },
  ],
  [
    {
      className: "ql-align",
      options: ["right", "center", "justify"],
    },
  ],
  [{ className: "ql-link" }],
];

export default formats;
