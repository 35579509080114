import { Typography } from "@mui/material";
import { CustomAlert } from "Components/CustomAlert/CustomAlert";
import { KYC_STATUS, PATHS } from "Constants";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "../EditCommunity.module.scss";

export const VerificationStatusAlert = ({
  kycStatus,
  isStripeReviewPending,
}) => {
  const { t } = useContext(LocalizationContext);
  const location = useLocation();
  let childEl = null;

  const title = () => {
    if (kycStatus === KYC_STATUS.IN_PROGRESS)
      return t("community.verifyIdentityTitleInProgress");
    if (kycStatus !== KYC_STATUS.ACCEPT) {
      childEl = (
        <Link
          to={PATHS.EARNINGS}
          className={styles.link}
          state={{ prev: location.pathname }}
        >
          <Typography variant="body1">
            {t("community.verifyIdentity")}
          </Typography>
        </Link>
      );
      return t("community.verifyIdentityTitle");
    }
    if (isStripeReviewPending) return t("community.stripePendingReview");
    return null;
  };
  if (!title()) return;
  return (
    <CustomAlert
      title={title()}
      severity="info"
      classNameWrapper={styles.earningsAlert}
    >
      {childEl}
    </CustomAlert>
  );
};
