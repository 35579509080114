import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as USER_API from "Services/Api/userApi";
import { logout as clearUserData } from "Services/Auth/auth.service";
import PusherService from "Services/Pusher/Pusher.service";

import { PATHS, POST_IN_GROUPS, TUTORIALS_PROPS } from "../Constants";

export const fetchUserMe = createAsyncThunk(
  "users/me",
  async (_, { rejectWithValue }) => {
    try {
      return await USER_API.getUser();
    } catch (err) {
      if (!err.data) {
        throw err;
      }
      return rejectWithValue(err.data);
    }
  }
);

export const editCommunity = createAsyncThunk(
  "users/me/community",
  async (params, { rejectWithValue }) => {
    try {
      return await USER_API.postCommunity(params);
    } catch (err) {
      if (!err.data) {
        throw err;
      }
      return rejectWithValue(err.data);
    }
  }
);

export const fetchUserStats = createAsyncThunk(
  "users/stats",
  async (params, { rejectWithValue }) => {
    try {
      const stats = await USER_API.getUserStats();
      const all = await USER_API.getUserStatsDetails(params);
      return {
        stats,
        details: {
          all,
        },
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserStatsDetailsByPeriod = createAsyncThunk(
  "users/stats/details",
  async (_, { rejectWithValue }) => {
    try {
      const week = await USER_API.getUserStatsDetails({ period: "week" });
      const month = await USER_API.getUserStatsDetails({ period: "month" });
      const year = await USER_API.getUserStatsDetails({ period: "year" });
      const all = await USER_API.getUserStatsDetails({ period: "all" });
      return {
        week,
        month,
        year,
        all,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk("users/update", async (params) => {
  try {
    const res = await USER_API.updateUser(params);
    return res?.data;
  } catch (err) {
    console.log(err);
    return err;
  }
});

export const uploadPhoto = createAsyncThunk(
  "users/upload/photo",
  async ({ image }) => {
    try {
      const s3Response = await USER_API.generateS3Url({
        type: "image",
        ContentType: image.type,
        extension: image.type.split("/")[1],
      });
      await fetch(s3Response.data.presignedUploadUrl, {
        method: "PUT",
        body: image,
      });

      const params = {
        profileImageUrl: s3Response.data.presignedUrl,
      };

      return await USER_API.updateUser(params);
    } catch (err) {
      console.log(err);
      return err;
    }
  }
);

export const fetchEmailNotificationSettings = createAsyncThunk(
  "users/settings/email-notifications",
  async () => {
    const response = await USER_API.getEmailNotificationSettings();
    return response.data;
  }
);

export const updateEmailNotificationSettings = createAsyncThunk(
  "users/settings/email-notifications/update",
  async (params) => {
    await USER_API.updateEmailNotificationSettings(params);
    return params;
  }
);

const initialState = {
  me: {
    loading: false,
    fulfilled: false,
    entities: {
      community: {
        description: "",
        perks: null,
        subscriptionPrice: null,
        postingRights: POST_IN_GROUPS.ONLY_ME,
      },
      stripeData: {},
      seenTutorials: {
        [TUTORIALS_PROPS.PROFILE_COMPLETE]: true,
        [TUTORIALS_PROPS.EDIT_COMMUNITY]: true,
        [TUTORIALS_PROPS.FIRST_CARD]: true,
        [TUTORIALS_PROPS.CREATE_CARD_PREMIUM_CHAT]: true,
        [TUTORIALS_PROPS.CREATE_CARD_FOLLOW_BACK]: true,
        [TUTORIALS_PROPS.CREATE_CARD_VIDEO_CALL]: true,
        [TUTORIALS_PROPS.CREATE_CARD_IMG]: true,
        [TUTORIALS_PROPS.CREATE_CARD_SUPPLY]: true,
        [TUTORIALS_PROPS.CREATE_CARD_PRICE]: true,
        [TUTORIALS_PROPS.CREATE_CARD_SCHEDULE]: true,
        [TUTORIALS_PROPS.FIRST_ORDER]: true,
      },
    },
  },
  stats: {
    loading: false,
    entities: {},
    details: {
      week: [],
      month: [],
      year: [],
      all: [],
    },
  },
  emailNotifications: {
    loading: false,
    entities: {},
  },
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    logout(state, action) {
      PusherService.disconnect();
      clearUserData();
      state = initialState;
    },
    changeUser(state, action) {
      state.me.entities = { ...state.me.entities, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserMe.pending, (state, action) => {
        state.me.loading = true;
      })
      .addCase(fetchUserMe.fulfilled, (state, action) => {
        state.me.loading = false;
        state.me.entities = action.payload;
        state.me.fulfilled = true;
      })
      .addCase(fetchUserMe.rejected, (state, action) => {
        if (
          action.payload?.error === "Suspended" &&
          !window.location.href.includes(PATHS.ACCOUNT_SUSPENDED)
        ) {
          window.location.href = `${window.location.origin}${PATHS.ACCOUNT_SUSPENDED}`;
        }
        state.loading = false;
      })
      .addCase(fetchUserStats.pending, (state, action) => {
        state.stats.loading = true;
      })
      .addCase(fetchUserStats.rejected, (state, action) => {
        state.stats.loading = false;
      })
      .addCase(fetchUserStats.fulfilled, (state, action) => {
        state.stats.loading = false;
        state.stats.entities = action.payload.stats;
        state.stats.details = {
          ...state.stats.details,
          ...action.payload.details,
        };
      })
      .addCase(fetchUserStatsDetailsByPeriod.pending, (state, action) => {
        state.stats.loading = true;
      })
      .addCase(fetchUserStatsDetailsByPeriod.rejected, (state, action) => {
        state.stats.loading = false;
      })
      .addCase(fetchUserStatsDetailsByPeriod.fulfilled, (state, action) => {
        state.stats.loading = false;
        state.stats.details = { ...state.stats.details, ...action.payload };
      })
      .addCase(uploadPhoto.fulfilled, (state, action) => {
        state.me.entities = {
          ...state.me.entities,
          profileImageUrl: action.payload?.data?.profileImageUrl,
        };
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const entities = action.payload || {};
        state.me.entities = { ...state.me.entities, ...entities };
      })
      .addCase(fetchEmailNotificationSettings.pending, (state) => {
        state.emailNotifications.loading = true;
      })
      .addCase(fetchEmailNotificationSettings.rejected, (state) => {
        state.emailNotifications.loading = false;
      })
      .addCase(fetchEmailNotificationSettings.fulfilled, (state, action) => {
        state.emailNotifications.loading = false;
        const { payload } = action;
        if (payload) {
          state.emailNotifications.entities = action.payload;
        }
      })
      .addCase(updateEmailNotificationSettings.fulfilled, (state, action) => {
        state.emailNotifications.entities = action.payload;
      })
      .addCase(editCommunity.fulfilled, (state, action) => {
        state.me.entities.community = {
          ...state.me.entities.community,
          ...action.payload,
        };
      });
  },
});

export const { logout, changeUser } = usersSlice.actions;

export default usersSlice.reducer;
