import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as IconClose } from "assets/svg/icon-close.svg";
import React from "react";

import { CustomTooltip } from "../CustomTooltip/CustomTooltip";
import styles from "./TutorialTooltip.module.scss";

export const TutorialTooltip = ({
  children,
  isOpen,
  setOpen,
  title,
  subTitle,
  ...props
}) => {
  return (
    <CustomTooltip
      isArrow={true}
      open={isOpen}
      setOpen={setOpen}
      isClick={true}
      title={
        <Box
          className={styles.tutorialBox}
          onClick={(e) => e.stopPropagation()}
        >
          <Typography variant="body4">{title}</Typography>
          <Typography variant="body2">{subTitle}</Typography>
          <IconButton onClick={setOpen}>
            <IconClose />
          </IconButton>
        </Box>
      }
      tooltipClassName={styles.tooltip}
      customTooltipArrow={styles.tooltipArrow}
      isCloseOutside={false}
      {...props}
    >
      {children}
    </CustomTooltip>
  );
};
