import {
  Box,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { SOCIAL_LINKS } from "Constants";
import { useContext, useRef } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./Profile.module.scss";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "5px 10px",
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));
const SocialMediaLinksItem = ({ social, onFormChange }) => {
  const { t } = useContext(LocalizationContext);

  const getLink = ({ socialPlatformId, link }) => {
    let prependLink = "";
    const found = SOCIAL_LINKS.find(
      (item) => item.socialPlatformId === socialPlatformId
    );
    if (found) prependLink = found.prependLink;
    return prependLink + link;
  };
  const getSicualLinkValue = ({ id, propName }) => {
    const found = SOCIAL_LINKS.find((item) => item.socialPlatformId === id);
    return found ? found[propName] : "";
  };
  const inputRef = useRef(null);

  return (
    <FormControl className={styles.socialListItem}>
      <Box className={styles.label}>{t(`default.${social.code}`)}</Box>
      <LightTooltip title={getLink(social)}>
        <TextField
          value={social.link}
          label={null}
          className={styles.input}
          placeholder=""
          inputProps={{ ref: inputRef }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                onClick={() => {
                  inputRef.current.focus();
                }}
              >
                <img
                  src={`social/${social.code}-outlined.svg`}
                  alt={t(`default.${social.code}`)}
                />
                <Typography
                  variant="body1"
                  className={`${styles.socialListItemHandle} opacity_05`}
                >
                  {getSicualLinkValue({
                    id: social.socialPlatformId,
                    propName: "adornment",
                  })}
                </Typography>
              </InputAdornment>
            ),
            onChange: (event) => onFormChange(event),
          }}
          type="text"
          autoComplete="off"
        />
      </LightTooltip>
    </FormControl>
  );
};
export default SocialMediaLinksItem;
