import React from "react";
import { Alert as MuiAlert } from "@mui/material";
const Alert = React.forwardRef(function Alert(
  { children, className, ...rest },
  ref
) {
  //   const [open, setOpen] = React.useState(true);
  //   const handleClose = (event, reason) => {
  //     if (reason === "clickaway") {
  //       return;
  //     }

  //     setOpen(false);
  //   };
  return (
    <MuiAlert
      ref={ref}
      severity="error"
      variant="outlined"
      sx={{ width: "100%", mb: "20px" }}
      className={className}
      {...rest}
    >
      {children}
    </MuiAlert>
  );
});
export default Alert;
