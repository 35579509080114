import { Box, Typography } from "@mui/material";
import { ReactComponent as IconReaction } from "assets/svg/icon-reaction.svg";
import { ReactComponent as IconReactionFilled } from "assets/svg/icon-reaction-filled.svg";
import React, { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { useBreakpoint } from "Utils/Hooks";

import { ReactionItem } from "../ReactionItem/ReactionItem";
import styles from "./ReactionsInfo.module.scss";

const mobileReactions = (reactions) => {
  return reactions
    .sort((a, b) => b.count - a.count)
    .slice(0, 3)
    .sort((a, b) => a.count - b.count);
};

const getTotal = (reactions) => {
  const sortedReactions = reactions.sort((a, b) => a.count - b.count);
  sortedReactions.pop();
  return sortedReactions.reduce((acc, item) => acc + item.count, 0);
};

export const ReactionsInfo = ({
  allReactions: originalAllReactions,
  openPopover,
  selectedReaction,
  handleSelect,
}) => {
  const { t } = useContext(LocalizationContext);
  const allReactions = [...originalAllReactions];
  const isEmpty = !allReactions?.length;
  const isSm = useBreakpoint("sm");
  const reactions = isSm ? mobileReactions(allReactions) : allReactions;

  return !isEmpty ? (
    <>
      <Box className={styles.reactionsWrapper}>
        {reactions.map((item, index) => (
          <ReactionItem
            key={item.name}
            data={item}
            selectedReaction={selectedReaction}
            handleSelect={handleSelect}
            notLast={index !== reactions.length - 1}
            openPopover={openPopover}
          />
        ))}
      </Box>
      <Box onClick={openPopover}>
        <Box component="span" className={styles.iconWrapper}>
          {selectedReaction.length ? <IconReactionFilled /> : <IconReaction />}
        </Box>
      </Box>
      {allReactions.length > 1 && isSm ? (
        <Typography variant="body1" className={styles.total}>
          {t("default.reactTotal", { total: getTotal(allReactions) })}
        </Typography>
      ) : null}
    </>
  ) : (
    <Box onClick={openPopover} className={styles.emptyReactions}>
      <Box component="span" className={styles.iconWrapper}>
        <IconReaction />
      </Box>
      <Typography variant="body1" className={styles.reactionsWrapperTitle}>
        {t("default.react")}
      </Typography>
    </Box>
  );
};
