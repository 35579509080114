import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { KYC_ERROR_STATUS, KYC_STATUS } from "Constants/index";
import React from "react";

import styles from "./OnboardingItem.module.scss";

export const OnboardingItem = ({
  isShowing,
  isDisabled,
  action,
  buttonText,
  title,
  description,
  btnIcon,
  isLoading,
  status,
}) => {
  if (!isShowing) {
    return null;
  }

  const isError = KYC_ERROR_STATUS[status?.toUpperCase()];

  return (
    <Box
      className={`${styles.wrapper} ${isDisabled ? styles.disabled : ""} ${
        isError ? styles.wrapperError : ""
      }`}
    >
      <Box className={styles.main}>
        <Typography variant="body4_16" component="p" className={styles.title}>
          {title}
        </Typography>
        <Typography
          variant="body2"
          className={`${styles.description} opacity_07`}
        >
          {description}
        </Typography>
      </Box>
      {status === KYC_STATUS.IN_PROGRESS ? (
        <Typography variant="body2" className={styles.statusText}>
          {buttonText}
        </Typography>
      ) : (
        <LoadingButton
          onClick={action}
          disabled={isDisabled}
          variant="contained"
          loading={isLoading}
        >
          <Typography variant="body1_medium">{buttonText}</Typography>
          {btnIcon}
        </LoadingButton>
      )}
    </Box>
  );
};
