import { Avatar, Box, Button, IconButton, Typography } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/svg/close-mobile-menu.svg";
import { ReactComponent as ClubIcon } from "assets/svg/club-icon.svg";
import { ReactComponent as EarningsIcon } from "assets/svg/earnings-icon.svg";
import { ReactComponent as InsightsIcon } from "assets/svg/insights-icon.svg";
import { ReactComponent as LogOutIcon } from "assets/svg/log-out-icon.svg";
import { ReactComponent as Logo } from "assets/svg/logo.svg";
import { ReactComponent as MembersIcon } from "assets/svg/members-icon.svg";
import { ReactComponent as MessagesIcon } from "assets/svg/messages-icon.svg";
import { ReactComponent as SeasonsIcon } from "assets/svg/seasons-icon.svg";
import { ReactComponent as TransactionsIcon } from "assets/svg/transactions-icon.svg";
import classNames from "classnames/bind";
import ActiveLink from "Components/ActiveLink";
import SettingsMenu from "Components/SettingsMenu";
import {
  LEVEL_BIGGER,
  LEVEL_GROUP,
  LEVEL_SMALLER,
  MainAppDomain,
  PATHS,
  USER_ME_STATUS,
} from "Constants/index";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toggleMobileMenu } from "Redux/appSlice";
import { getGlobalCountUnreadMessages } from "Redux/selectors/messengerSelectors";
import { getUsersMeCommunityGuidelinesAccepted } from "Redux/selectors/userSelector";
import { logout } from "Redux/usersSlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./MobileMenu.module.scss";

const MobileMenu = () => {
  const { t } = useContext(LocalizationContext);
  const userMe = useSelector((state) => state.users.me.entities);
  const isMobileMenuVisible = useSelector(
    (state) => state.app.isMobileMenuVisible
  );
  const messagesCount = useSelector(getGlobalCountUnreadMessages);
  const userLevel = useSelector((state) => state.users.me.entities.level);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   dispatch(setLoader(true));
  //   dispatch(fetchUserMe()).finally(() => dispatch(setLoader(false)));
  // }, []);

  const closeMenu = () => {
    document.body.classList.remove("mobile-menu-open");
    dispatch(toggleMobileMenu());
  };

  const handleLogOut = () => {
    dispatch(logout());
    closeMenu();
    window.location.href = `${MainAppDomain}/login#creator`;
  };

  const accountType =
    userMe?.level === LEVEL_GROUP
      ? t("account.groupAccount")
      : userMe?.level === LEVEL_BIGGER || userMe?.level === LEVEL_SMALLER
      ? t("account.individualAccount")
      : t("account.profileTmpType");

  const communityGuidelinesAccepted = useSelector(
    getUsersMeCommunityGuidelinesAccepted
  );
  if (!isMobileMenuVisible) return null;
  const cx = classNames.bind(styles);
  const disableItems =
    userMe?.status === USER_ME_STATUS.NEW ||
    !userLevel ||
    !communityGuidelinesAccepted;
  const menuItemClassName = cx(
    "menuItem",
    disableItems && "menuDisable prevent-action"
  );
  const myClubClassName = cx(
    (userMe?.status === USER_ME_STATUS.NEW || !userLevel) &&
      "menuDisable prevent-action"
  );
  return (
    <Box className={styles.mobileMenu}>
      <Box className={styles.menuContainer}>
        <Box className={styles.menuHeader}>
          <Link onClick={closeMenu} to={PATHS.HOME} className={styles.logo}>
            <Logo />
          </Link>
          <IconButton className={styles.closeBtn} onClick={closeMenu}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          className={styles.profile}
          onClick={() => {
            closeMenu();
            navigate(PATHS.PROFILE);
          }}
        >
          <Avatar
            src={userMe?.profileImageUrl}
            className={styles.profilePicture}
          />
          <Box className={styles.profileTextBlock}>
            <Typography variant="h5" className={styles.profileName}>
              {userMe?.name}
            </Typography>
            <Typography variant="body1" className={styles.profileCat}>
              {accountType}
            </Typography>
          </Box>
        </Box>

        <Box className={styles.menu}>
          <ActiveLink
            onClick={closeMenu}
            to={PATHS.COMMUNITY}
            className={cx("menuItem", myClubClassName)}
            activeClass={styles.menuItemActive}
          >
            <ClubIcon className={`${styles.icon} ${styles.clubIcon}`} />
            <Typography variant="h4" className={styles.menuItemText}>
              {t("default.myClub")}
            </Typography>
          </ActiveLink>
          {/*{userMe?.level === LEVEL_BIGGER ? (*/}
          {/*  <ActiveLink*/}
          {/*    onClick={closeMenu}*/}
          {/*    to={PATHS.SEASONS}*/}
          {/*    className={styles.menuItem}*/}
          {/*    activeClass={styles.menuItemActive}*/}
          {/*  >*/}
          {/*    <SeasonsIcon className={`${styles.icon} ${styles.seasonsIcon}`} />*/}
          {/*    <Typography variant="h4" className={styles.menuItemText}>*/}
          {/*      {t("default.seasons")}*/}
          {/*    </Typography>*/}
          {/*  </ActiveLink>*/}
          {/*) : null}*/}
          {userMe?.level === LEVEL_SMALLER || userMe?.level === LEVEL_BIGGER ? (
            <ActiveLink
              onClick={closeMenu}
              to={PATHS.LIMITED_EDITION}
              className={menuItemClassName}
              activeClass={styles.menuItemActive}
            >
              <SeasonsIcon className={`${styles.icon} ${styles.seasonsIcon}`} />
              <Typography variant="h4" className={styles.menuItemText}>
                {t("default.sales")}
              </Typography>
            </ActiveLink>
          ) : null}
          <ActiveLink
            onClick={closeMenu}
            to={PATHS.FOLLOWERS}
            className={menuItemClassName}
            activeClass={styles.menuItemActive}
          >
            <MembersIcon className={`${styles.icon}`} />
            <Typography variant="h4" className={styles.menuItemText}>
              {t("community.members")}
            </Typography>
          </ActiveLink>
          <ActiveLink
            onClick={closeMenu}
            to={PATHS.CHAT}
            className={menuItemClassName}
            activeClass={styles.menuItemActive}
          >
            <Box className={styles.menuItemIcon}>
              {messagesCount ? (
                <Typography
                  variant="text_12_display_bold"
                  className={styles.messagesCount}
                >
                  {messagesCount}
                </Typography>
              ) : null}
              <MessagesIcon
                className={`${styles.icon} ${styles.insightsIcon}`}
              />
            </Box>
            <Typography variant="h4" className={styles.menuItemText}>
              {t("default.messages")}
            </Typography>
          </ActiveLink>
          <ActiveLink
            onClick={closeMenu}
            to={PATHS.INSIGHTS}
            className={menuItemClassName}
            activeClass={styles.menuItemActive}
          >
            <InsightsIcon className={styles.icon} />
            <Typography variant="h4" className={styles.menuItemText}>
              {t("default.insights")}
            </Typography>
          </ActiveLink>
          <ActiveLink
            onClick={closeMenu}
            to={PATHS.EARNINGS}
            className={menuItemClassName}
            activeClass={styles.menuItemActive}
          >
            <EarningsIcon className={`${styles.icon} ${styles.iconEarnings}`} />
            <Typography variant="h4" className={styles.menuItemText}>
              {t("default.earnings")}
            </Typography>
          </ActiveLink>
          <ActiveLink
            onClick={closeMenu}
            to={PATHS.TRANSACTIONS}
            className={menuItemClassName}
            activeClass={styles.menuItemActive}
          >
            <TransactionsIcon className={`${styles.icon}`} />
            <Typography variant="h4" className={styles.menuItemText}>
              {t("default.transactions")}
            </Typography>
          </ActiveLink>
          <Box className={menuItemClassName}>
            <SettingsMenu
              isMobile={true}
              onClickSettingsMenu={closeMenu}
              itemsClassNames={{ [PATHS.EDIT_COMMUNITY]: myClubClassName }}
            />
          </Box>
          <Box className={styles.divider} />
          <Button
            onClick={handleLogOut}
            className={styles.logOutBtn}
            startIcon={<LogOutIcon className={styles.menuItemIcon} />}
          >
            {t("default.logOut")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MobileMenu;
