import "react-quill/dist/quill.snow.css";

import { Box } from "@mui/material";
import React from "react";
import ReactQuill from "react-quill";

import CustomToolbar from "./CustomToolbar";
import styles from "./Editor.module.scss";

const formats = [
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "script",
  "header",
  "blockquote",
  "list",
  "direction",
  "align",
  "link",
];

const Editor = ({
  data,
  reactQuillRef,
  editorToolbarId,
  onChange,
  onKeyDown = () => {},
  customFormats,
  className,
  placeholder,
  readOnly,
}) => {
  const modules = {
    toolbar: {
      container: `#${editorToolbarId}`,
    },
  };

  return (
    <Box className={`${styles.editor} ${className || ""}`}>
      <CustomToolbar
        editorToolbarId={editorToolbarId}
        customFormats={customFormats}
      />
      <ReactQuill
        ref={reactQuillRef}
        value={data}
        modules={modules}
        formats={formats}
        onChange={onChange}
        preserveWhitespace={true}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        readOnly={readOnly}
      />
    </Box>
  );
};

export default Editor;
